import React, { useState, useEffect } from "react";
import moment from "moment-timezone";
import Datetime from "react-datetime";

import { statesList } from "../../components/data/statesList";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt, faLocationArrow, faSearch } from "@fortawesome/free-solid-svg-icons";

import { Form, Button, InputGroup } from "react-bootstrap";


export const SearchListings = (props) => {

  const [highlightSubmitButton, setHighlightSubmitButton] = useState(false);

  const [searchListingsFormData, setSearchListingsFormData] = useState({});
  useEffect(() => {
    setSearchListingsFormData(props.formData);
  }, [props.formData]);

  function setFormValues(e) {
    // update state of searchListingsFormData
    let currentSearchListingsFormData = searchListingsFormData;
    currentSearchListingsFormData[e.target.name] = e.target.value;
    setSearchListingsFormData(currentSearchListingsFormData);
    setHighlightSubmitButton(true);
  }


  const [searchState, setSearchState] = useState(0);
  useEffect(() => {
    setSearchState(props.formData.state);
  }, [props.formData.state]);
  function setStateValue(e) {
    let currentSearchListingsFormData = searchListingsFormData;
    if ( Object.values(statesList).includes(e.target.value) ) {
      currentSearchListingsFormData[e.target.name] = e.target.value;
    } else {
      delete currentSearchListingsFormData[e.target.name];
    }
    setSearchListingsFormData(currentSearchListingsFormData);
    setHighlightSubmitButton(true);

    setSearchState(e.target.value);
  }


  const [dateRangeStart, setDateRangeStart] = useState(0);
  useEffect(() => {
    setDateRangeStart(props.formData.start);
  }, [props.formData.start]);
  const changeDateRangeStart = (e) => {
    let currentSearchListingsFormData = searchListingsFormData?searchListingsFormData:{};
    setDateRangeStart(e.format('MM-DD-YYYY'));
    currentSearchListingsFormData['start'] = e.format('MM-DD-YYYY');
    setSearchListingsFormData(currentSearchListingsFormData);
    setHighlightSubmitButton(true);

    // set next day after dateRangeStart
    changeDateRangeEnd(moment(e).add(1, 'day'));

    // auto-click End Date field
    document.querySelector('#searchListingsHeader #dateRangeEnd input').focus();
  };

  const [dateRangeEnd, setDateRangeEnd] = useState(0);
  useEffect(() => {
    setDateRangeEnd(props.formData.end);
  }, [props.formData.end]);
  const changeDateRangeEnd = (e) => {
    let currentSearchListingsFormData = searchListingsFormData?searchListingsFormData:{};
    setDateRangeEnd(e.format('MM-DD-YYYY'));
    currentSearchListingsFormData['end'] = e.format('MM-DD-YYYY');
    setSearchListingsFormData(currentSearchListingsFormData);
    setHighlightSubmitButton(true);
  };

  var isValidEndDate = function(current) {
    // check date is NOT BEFORE user-selected start
    if ( dateRangeStart ) {
      if ( !current.isAfter( moment(dateRangeStart).add(-1, 'day') ) ) { return false; }
    }

    // check date is NOT BEFORE TODAY
    if ( !current.isAfter( moment().add(-1, 'day') ) ) { return false; }

    return true;
  };


  return (
    <>
      <Form id="searchListingsHeader" onSubmit={ (e) => props.searchListings(e.preventDefault()) }>

        <div className="d-flex justify-content-evenly justify-content-lg-between">

          <Form.Group id="searchText">
            <InputGroup>
              <Form.Control 
                name="searchText" 
                defaultValue={props.formData.searchText && props.formData.searchText} 
                type="text"
                htmlSize="30"
                placeholder="Search for destinations" 
                onChange={setFormValues} 
                onBlur={setFormValues} 
                className="border-0 ms-2"
              />
            </InputGroup>
          </Form.Group>

          <div className="d-flex justify-content-center align-items-center me-4">
            <Form.Group id="dateRangeStart">
              <Datetime
                className="text-dark"
                dateFormat="MM/DD/YYYY"
                timeFormat={false}
                onChange={changeDateRangeStart}
                closeOnSelect={true}
                isValidDate={(current) => {return current.isAfter( moment().add(-1, 'day') )}} // check date is NOT BEFORE TODAY

                renderInput={(props, openCalendar, closeCalendar) => (
                  <InputGroup>
                    <Form.Label onClick={openCalendar} role="button" className="d-flex align-items-center pe-2 m-0">
                      <FontAwesomeIcon icon={faCalendarAlt} className="text-black" size="lg" />
                    </Form.Label>
                    <Form.Control
                      type="text"
                      defaultValue={ ( dateRangeStart ) ? moment(dateRangeStart).format("MM/DD/YYYY") : "" }
                      placeholder={moment().format("MM/DD/YYYY")}
                      onFocus={openCalendar}
                      onChange={(e) => { changeDateRangeStart(e.target.value) }}
                      className="border-0 pe-0 me-1"
                      style={{width: '5.33em'}}
                    />
                  </InputGroup>
                )}
              />
            </Form.Group>

            <span className="text-gray-600">&mdash;</span>

            <Form.Group id="dateRangeEnd">
              <Datetime
                className="text-dark"
                dateFormat="MM/DD/YYYY"
                timeFormat={false}
                onChange={changeDateRangeEnd}
                closeOnSelect={true}
                isValidDate={isValidEndDate}

                renderInput={(props, openCalendar, closeCalendar) => (
                  <InputGroup>
                    <Form.Control
                      type="text"
                      defaultValue={ ( dateRangeEnd ) ? moment(dateRangeEnd).format("MM/DD/YYYY") : "" }
                      placeholder="set end date"
                      onFocus={openCalendar}
                      onChange={(e) => { changeDateRangeEnd(e.target.value) }}
                      className="border-0 ps-0 ms-1"
                      style={{width: '6.33em'}}
                    />
                  </InputGroup>
                )}
              />
            </Form.Group>
          </div>

          <Form.Group id="searchState" className="d-flex align-items-center">
            <FontAwesomeIcon icon={faLocationArrow} className="text-black" />
            <Form.Select 
              id="state" 
              name="state" 
              value="MT" 
              /*value={searchState}*/ 
              onChange={setStateValue} 
              onBlur={setStateValue}
              className="border-0 shadow-none"
            >
              <option value="MT">Montana</option>
{/*
              <option value={false}>State</option>
              {
                Object.keys(statesList).map(function (key, i) {
                  return (
                    <option value={statesList[key]} key={i}>{key}</option>
                  );
                })
              }
*/}
            </Form.Select>
          </Form.Group>

          <Button 
            variant={highlightSubmitButton?'primary':'outline-primary'} 
            type="submit"
            className="rounded-circle ms-3"
            style={{lineHeight: 0}}
          >
            <FontAwesomeIcon icon={faSearch} />
          </Button>

        </div>

      </Form>
    </>
  );
}
