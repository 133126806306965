import React from "react";
import { Routes } from "../../routes";
import queryString from "query-string";

import homepageHero from "../../assets/img/rec-x/hero/homepage-hero-1.jpg";

import { SearchListings } from "../../components/forms/searchPublicListings";

import PublicFooter from '../../components/PublicFooter';

/*
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOutAlt, faUser, faChartPie } from "@fortawesome/free-solid-svg-icons";
import { faUserCircle } from "@fortawesome/free-regular-svg-icons";
*/

import { Col, Row, Image, Container } from "react-bootstrap";


const Homepage = (props) => {

  let searchListingsFormData = {};
  const searchListings = () => {
    let queryHash = queryString.stringify(searchListingsFormData);
    window.location.href = Routes.Search.path+'#'+queryHash; // Send to search page w/ hash parameters
    // console.log(Routes.Search.path+'#'+queryHash);
  }

  return (
    <>
      <section className="homepage-hero">
        <Image src={homepageHero} width="100%" />
      </section>

      <div className="section pt-0 mt-5">
        <Container>

          <Row className="justify-content-center mt-5 mt-lg-6">
            <Col xs={6} md={4} className="text-center mb-4">
              <div className="icon icon-shape icon-xl bg-white shadow-lg border-light rounded-circle icon-primary mb-4">
                <span className="flaticon-fishing"></span>
              </div>
              <h3 className="fw-bolder">20</h3>
              <p className="text-tertiary">Blue Ribbon Fishing</p>
            </Col>
            <Col xs={6} md={4} className="text-center mb-4">
              <div className="icon icon-shape icon-xl bg-white shadow-lg border-light rounded-circle icon-primary mb-4">
                <span className="flaticon-deer"></span>
              </div>
              <h3 className="fw-bolder">50+</h3>
              <p className="text-tertiary">Big Game Hunting</p>
            </Col>
            <Col xs={6} md={4} className="text-center">
              <div className="icon icon-shape icon-xl bg-white shadow-lg border-light rounded-circle icon-primary mb-4">
                <span className="flaticon-fishing"></span>
              </div>
              <h3 className="fw-bolder">22</h3>
              <p className="text-tertiary">Private Pond Fishing</p>
            </Col>
          </Row>

          <hr className="mt-4 mb-5" />

          <Row>
            <Col>
              <SearchListings formData={searchListingsFormData} searchListings={searchListings} />
            </Col>
          </Row>

        </Container>
      </div>


      <PublicFooter />
    </>
  );
};
export default Homepage;
