import { useState, useRef, useEffect, useCallback } from "react";
import Cookies from "js-cookie";
import { Form, Button, InputGroup, Collapse, OverlayTrigger } from "react-bootstrap";
import Preloader from "../../components/Preloader";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faChevronUp, faChevronDown, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { infoTooltip } from "../../components/InfoTooltip";

import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import { NavigationControl, FullscreenControl } from 'mapbox-gl';
import * as turf from "@turf/turf";
import proj4 from "proj4";
import { montanaCountyNames } from "./countyNames"
import { excludedParcelOwners } from './excludedParcelOwners';
import { mapStyles } from "./mapStyles";
import SelectedParcelsMenu from "./SelectedParcelsMenu";
import { lineLabelStyles, defaultLabelStyles } from './labelStyles';

import campground from '../../assets/img/map-icons/campground.svg';

import DrawingTools from "./DrawingTools";
import ClaimParcels from "./ClaimParcels";
import ParcelSearch from "./ParcelSearch";


const Map = (props) => {
  const addingProperty = props.mapMode === "addProperty" ? true : false;
  const addingListing = props.mapMode === "addListing" ? true : false;
  const editingListing = props.mapMode === "editListing" ? true : false;
  const isDrawing = addingListing || editingListing ? true : false;

  let currentUserData = Cookies.get('authToken');
  currentUserData = currentUserData ? JSON.parse(currentUserData).data : null;
  let authToken = currentUserData.accessToken;

  mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;
  const mapContainer = useRef(null)
  const map = useRef(null)
  const draw = useRef(null)
  const defaultCenter = [-110.3626, 46.8797]
  const [zoom, setZoom] = useState(6)
  const [stateName, setStateName] = useState("Montana")
  const [countyName, setCountyName] = useState("")
  const [ownerName, setOwnerName] = useState("")
  const [MT_centroids, setMT_centroids] = useState(null)
  const [searchResults, setSearchResults] = useState([])
  const [removedResults, setRemovedResults] = useState([]);
  const [propertyName, setPropertyName] = useState("")
  const [propertyCentroid, setPropertyCentroid] = useState(null)
  const [propertyBbox, setPropertyBbox] = useState(null)
  const [ownershipConfirmed, setOwnershipConfirmed] = useState(false)
  const [areParcelsClaimed, setParcelsClaimed] = useState(false)
  const [claimedParcels, setClaimedParcels] = useState([])
  const [searchModalOpen, setSearchModalOpen] = useState(true)
  const [claimModalOpen, setClaimModalOpen] = useState(false)
  const [sidebarsVisible, setSidebarsVisible] = useState(false)
  const [isAddingMarker, setIsAddingMarker] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isMobileMenuCollapsed, setIsMobileMenuCollapsed] = useState(false)

  const [drawingColor, setDrawingColor] = useState("#00ffff");
  const drawingColorRef = useRef(drawingColor);
  const [drawingMode, setDrawingMode] = useState("simple_select");
  const drawingModeRef = useRef(drawingMode);
  const [markerIcon, setMarkerIcon] = useState(campground);
  const markerIconRef = useRef()
  const searchResultsRef = useRef(searchResults)

  const [propertyFeatures, setPropertyFeatures] = useState({
    "type": "FeatureCollection",
    "features": []
  })
  const propertyFeaturesRef = useRef(propertyFeatures);
  const ownerNameRef = useRef(ownerName)

  const parcelsSourceId = "montana-parcels";
  const parcelsFillId = `${parcelsSourceId}-fill`;
  const parcelsLineId = `${parcelsSourceId}-line`;
  const highlightedParcelsLineId = `${parcelsSourceId}-highlighted`;
  const mapLayerId = "mapbox/satellite-streets-v12?optimize=true";

  let parcelsOwnedByOwner = []
  let storedParcels = [];

  let bbox = null
  const savedFeatures = props.listingData?.features

  if (!savedFeatures) {
    bbox = props.listingData?.propertyData.property_bbox
  } else if (savedFeatures.length !== 0) {
    bbox = turf.bbox({
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: savedFeatures[0].geometry.coordinates
      }
    });
  }

  useEffect(() => {
    searchResultsRef.current = searchResults
  }, [searchResults])

  useEffect(() => {
    propertyFeaturesRef.current = propertyFeatures
  }, [propertyFeatures])

  useEffect(() => {
    markerIconRef.current = markerIcon
  }, [markerIcon])

  useEffect(() => {
    ownerNameRef.current = ownerName
  }, [ownerName])

  useEffect(() => {
    drawingColorRef.current = drawingColor
  }, [drawingColor])

  useEffect(() => {
    drawingModeRef.current = drawingMode
  }, [drawingMode])

  useEffect(() => {
    if (searchModalOpen && countyName !== "") {
      handleSearch()
    }
  }, [countyName])

  // Load map
  useEffect(() => {
    if (!map.current) {
      map.current = new mapboxgl.Map({
        container: mapContainer.current,
        style: `mapbox://styles/${mapLayerId}`,
        center: addingProperty ? defaultCenter : ( addingListing ? props.thisPropertyData.property_centroid : props.listingData.propertyData.property_centroid),
        zoom: zoom,
        minZoom: 8
      });

      const handleMapLoad = () => {
        addMapControls();
        
        if (addingProperty || addingListing) loadParcels();
        if (editingListing) loadSavedFeatures()
        
        let clickTimeout = null;
        let isDragging = false;
        
        function handleMapClick(e) {
          if (isDragging || isDrawing) {
            isDragging = false;
            return;
          }
          const features = e.features ? [...e.features] : [];
          clearTimeout(clickTimeout);
          clickTimeout = setTimeout(() => {
            if (features.length > 0) {
              let city, state, zipcode, stateZip
              const { CityStateZ, PARCELID, OwnerName, AddressLin, CountyName } = features[0].properties;

              if (CityStateZ) {
                [city, stateZip] = CityStateZ.split(', ');
                [state, zipcode] = stateZip.split(' ');
              }

              const parcel = {
                parcelID: PARCELID,
                ownerName: OwnerName,
                street_address: AddressLin,
                county: CountyName,
                city,
                state,
                zipcode,
              }

              handleParcelClick(parcel);
            }
          }, 200);
        }
        map.current.on("touchstart", () => isDragging = false);
        map.current.on("touchmove", () => isDragging = true);
        map.current.on("click", 'county-parcels-fill', handleMapClick);
        map.current.on("touchend", 'county-parcels-fill', handleMapClick);
        map.current.on("dblclick", "county-parcels-fill", () => clearTimeout(clickTimeout));
        map.current.on("click", selectTopLayer)
        map.current.on("touchend", selectTopLayer)
        map.current.on("draw.modechange", (e) => setDrawingMode(e.mode));
        map.current.on("zoomend", function () {
          let zoomLevel = map.current.getZoom();
          if (addingListing) setZoom(zoomLevel);
        })
      }
      map.current.on("load", handleMapLoad)

      return () => {
        map.current.off("load", handleMapLoad)
        map.current.off("draw.modechange")
        map.current.off("zoomend")
        map.current.off("click", selectTopLayer)
        map.current.off("touchend", selectTopLayer)
        map.current.remove()
      }
    }
  }, []);

  useEffect(() => {
    (async function fetchAndStoreCentroidData() {
      if (addingProperty) {
        try {
          const response = await fetch("data/MT_centroids.geojson")
          const data = await response.json()
          setMT_centroids(data)
        } catch (error) {
          console.error("Error fetching centroid data:", error)
        }
      }
    })()
  },[])
    
  useEffect(() => {
    if (areParcelsClaimed && ownershipConfirmed) {
      claimedParcels[0]?.ownerName &&
        setPropertyName(claimedParcels[0].ownerName)
      setClaimModalOpen(true)
    }
  }, [areParcelsClaimed, ownershipConfirmed, claimedParcels])


  // Map Setup
  function loadParcels() {
    if (!map.current) return;

    if (!map.current.getSource(parcelsSourceId)) {
      map.current.addSource(parcelsSourceId, {
        type: "vector",
        url: process.env.REACT_APP_MAPBOX_TILESET_URL,
      })

      const handleSourceData = function (e) {
        if (e.sourceId === parcelsSourceId && map.current.isSourceLoaded(parcelsSourceId)) {
          map.current.off("sourcedata", handleSourceData)
          map.current.getLayer(highlightedParcelsLineId)
            && map.current.removeLayer(highlightedParcelsLineId)

          const excludedOwnersFilter = excludedParcelOwners.reduce((filter, ownerName) => {
            return [...filter, ["!=", "OwnerName", ownerName]];
          }, ["all", ["==", "CountyName", countyName], ["has", "OwnerName"]]);

          if (addingProperty) {
            map.current.getLayer(parcelsFillId) &&
              map.current.removeLayer(parcelsFillId);
            map.current.addLayer({
              id: parcelsFillId,
              type: "fill",
              source: parcelsSourceId,
              "source-layer": "montana-parcels",
              paint: {
                "fill-color": "#00ffff",
                "fill-opacity": 0.5,
              },
              minzoom: 8,
              filter: excludedOwnersFilter,
              });

            map.current.getLayer("parcel-results-line") &&
              map.current.removeLayer("parcel-results-line");
            map.current.addLayer({
              id: "parcel-results-line",
              type: "line",
              source: parcelsSourceId,
              "source-layer": "montana-parcels",
              paint: {
                "line-color": "#ffffff",
                "line-width": 1, 
                "line-opacity": 1, 
              },
              minzoom: 8,
              filter: excludedOwnersFilter,
              });

            map.current.getLayer("county-parcels-fill") &&
              map.current.removeLayer("county-parcels-fill");
            map.current.addLayer({
              id: "county-parcels-fill",
              type: "fill",
              source: parcelsSourceId,
              "source-layer": "montana-parcels",
              paint: {
                "fill-color": "#ffffff",
                "fill-opacity": 0,
              },
              minzoom: 8,
              filter: ["==", "CountyName", countyName],
            });

            map.current.getLayer(highlightedParcelsLineId) &&
              map.current.removeLayer(highlightedParcelsLineId);
            map.current.addLayer({
              source: parcelsSourceId,
              id: highlightedParcelsLineId,
              "source-layer": "montana-parcels",
              type: "fill",
              paint: {
                "fill-color": "#ff00ff",
                "fill-opacity": 0.65,
              },
              minzoom: 8,
              filter: [
                "in",
                "PARCELID",
                ...storedParcels.map((parcel) => parcel.parcelID),
              ],
            });

            map.current.getLayer("county-parcels-line") &&
              map.current.removeLayer("county-parcels-line");
            map.current.addLayer({
              id: "county-parcels-line",
              type: "line",
              source: parcelsSourceId,
              "source-layer": "montana-parcels",
              paint: {
                "line-color": "#ffffff",
                "line-width": 1, 
                "line-opacity": 0.8, 
              },
              minzoom: 8,
              filter: ["==", "CountyName", countyName],
            });

          } else if (isDrawing) {
            let thisPropertyParcels
            addingListing ? thisPropertyParcels = props.thisPropertyData.parcels : thisPropertyParcels = props.listingData.propertyData.parcels
        
            map.current.getLayer(highlightedParcelsLineId) &&
              map.current.removeLayer(highlightedParcelsLineId);
            map.current.addLayer({
              source: parcelsSourceId,
              id: highlightedParcelsLineId,
              "source-layer": "montana-parcels",
              type: "line",
              paint: {
                "line-color": "#ff00ff",
                "line-opacity": 0.9,
                "line-width": 3,
              },
              minzoom: 8,
              filter: [
                "in",
                "PARCELID",
                ...thisPropertyParcels
              ],
            });
        
            fetch("/data/MT_centroids.geojson")
            .then(response => response.json())
            .then((data) => {
              let centroid = data.features.find(
                f => f.properties.NAME === props.thisPropertyData?.address?.county?.toUpperCase())?.geometry.coordinates;

              if (centroid && map.current) {
                map.current.fitBounds(props.thisPropertyData.property_bbox, { padding: 80 }) 
              }
            });  
          }
        }
      }
      map.current.on("sourcedata", handleSourceData)
    }
  }

  function loadSavedFeatures() {
    map.current.getLayer('property-line') 
    && map.current.removeLayer('property-line');

    !map.current.getSource('montana-parcels') 
      && map.current.addSource('montana-parcels', {
          type: "vector",
          url: process.env.REACT_APP_MAPBOX_TILESET_URL,
        }); 

    map.current.addLayer({
      source: 'montana-parcels',
      id: 'property-line',
      "source-layer": "montana-parcels",
      type: "line",
      paint: {
        "line-color": "#ff00ff",
        "line-opacity": 0.9,
        "line-width": 3,
      },
      minzoom: 8,
      filter: [
        "in",
        "PARCELID",
        ...props.listingData.propertyData.parcels
      ],
    })

    const addedMarkers = new Set();

    props.listingData.map_geoJSON.features.forEach((feature) => {
      const ID = feature.id

      if (feature.properties.type === 'drawn-feature') draw.current.add(feature)
        
      else if (feature.properties?.type === 'marker' && !addedMarkers.has(ID)) {
        function updateSVGColor(newColor) {
          fetch(feature.properties.icon)
          .then(response => response.text())
          .then(svgContent => {
              const parser = new DOMParser()
              const svgDoc = parser.parseFromString(svgContent, 'image/svg+xml')
              const svgElement = svgDoc.documentElement
              svgElement.setAttribute('fill', newColor || '#00ffff')
              const svgString = new XMLSerializer().serializeToString(svgElement)
              el.style.backgroundImage = `url('data:image/svg+xml;base64,${btoa(svgString)}')`
          })
        }

        const el = document.createElement('div');  
        el.className = 'marker';
        el.id = ID;
        el.style.cssText = `
          width: 40px;
          height: 40px;
          background-size: fit;
          background-repeat: no-repeat;
          cursor: pointer;
        `
        updateSVGColor(feature.properties.color)

        const marker = new mapboxgl.Marker(el, { draggable: true})
          .setLngLat(feature.geometry.coordinates)
          .addTo(map.current)

        addedMarkers.add(ID);
      
        const handleSavedMarkerPopup = () => {
          addPopup(ID, feature.geometry.coordinates)
          marker.setPopup(popup)
        }

        el.addEventListener('click', handleSavedMarkerPopup)
        el.addEventListener('touchend', handleSavedMarkerPopup)

        marker.on('dragend', () => {
          feature.geometry.coordinates = marker.getLngLat().toArray();
          const labelSource = map.current.getSource(`label-${ID}`)
          if (labelSource) labelSource.setData(feature)
        })
      }

      const featureId = `feature-${feature.id}`
      const labelId = `label-${feature.id}`

      if (map.current.getLayer(featureId)) {
        map.current.removeLayer(featureId)
        map.current.removeSource(featureId)
      }
      
        if (!map.current.getSource(labelId)) {
          map.current.addSource(labelId, {
            type: 'geojson',
            data: {
              type: 'Feature',
              geometry: feature.geometry,
              properties: {
                label: (!feature.properties.label || feature.properties.label === "" || feature.properties.label === "Click to add label...") 
                ? "Click to add label..." 
                : feature.properties.label,
              }
            }
          });
        }
        
        if (!map.current.getLayer(labelId)) {
          const isLine = feature.geometry.type === 'LineString';
          map.current.addLayer({
            type: 'symbol',
            id: labelId,
            source: labelId,
            layout: isLine ? lineLabelStyles.layout : defaultLabelStyles.layout,
            paint: isLine ? lineLabelStyles.paint : defaultLabelStyles.paint,
            minzoom: defaultLabelStyles.minzoom,
          });
        }
    });
    map.current && map.current.fitBounds(bbox, { padding: 150, maxZoom: 13.5 })
  }

  let popup
  const addPopup = function (ID, coords) { 
    closePopups()

    if (drawingModeRef.current === 'draw_polygon' || drawingModeRef.current === "draw_line_string") return

    const currentPropertyFeatures = propertyFeaturesRef.current
    const feature = currentPropertyFeatures.features.find(feature => feature.id === ID)
    setDrawingColor(feature?.properties?.color || '#00ffff')

    const popupHTML = generatePopupHTML(ID)
    popup = new mapboxgl.Popup({ closeButton: false })
      .setLngLat(coords)
      .setHTML(popupHTML)

    popup.on('open', () => {
      const deleteBtn = document.getElementById(`deleteButton-${ID}`)
      const saveBtn = document.getElementById(`saveButton-${ID}`)
      const labelInput = document.getElementById(`labelInput-${ID}`);
    
      deleteBtn && deleteBtn.addEventListener('click', () => handlePopupDelete(ID, popup))
      saveBtn && saveBtn.addEventListener('click', () => handlePopupSave(ID, labelInput))
    })

    popup.addTo(map.current)

    return popup
  }

  const selectTopLayer = function (e) {
    const features = map.current.queryRenderedFeatures(e.point)
    if (!features) return

    const labelLayer = features.find(feature => feature.properties.label)

    let featureId
    if (labelLayer) featureId = labelLayer.layer.id.split('-')[1]
    else if (features.length > 0) featureId = features[0].properties.id
    
    featureId && addPopup(featureId, e.lngLat)
  }

  const addMapControls = useCallback(() => {
    if (!map.current) return

    draw.current = new MapboxDraw({
      userProperties: true,
      displayControlsDefault: false,
      controls: {
        point: (addingListing || editingListing),
        line_string: (addingListing || editingListing),
        polygon: (addingListing || editingListing),
      },
      styles: mapStyles
    });

    map.current.addControl(new FullscreenControl());
    map.current.addControl(new NavigationControl());

    if (isDrawing) {
      map.current.addControl(new MarkerIconControl());
      map.current.addControl(draw.current)
    }
  })

  class MarkerIconControl {
    onAdd() {
      this.map = map;
      this.container = document.createElement('div');
      this.container.className = 'mapboxgl-ctrl mapboxgl-ctrl-group';
      const button = document.createElement('button');
      button.className = 'mapbox-gl-draw_ctrl-draw-btn';
      button.id = 'marker-icon-control';
      const svg = `<svg xmlns="http://www.w3.org/2000/svg" width="50%" viewBox="0 0 576 512"><!--!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M377 52c11-13.8 8.8-33.9-5-45s-33.9-8.8-45 5L288 60.8 249 12c-11-13.8-31.2-16-45-5s-16 31.2-5 45l48 60L12.3 405.4C4.3 415.4 0 427.7 0 440.4V464c0 26.5 21.5 48 48 48H288 528c26.5 0 48-21.5 48-48V440.4c0-12.7-4.3-25.1-12.3-35L329 112l48-60zM288 448H168.5L288 291.7 407.5 448H288z"/></svg>`;
      button.innerHTML = svg;
      this.container.appendChild(button);
      button.onclick = () => {
        setIsAddingMarker((prev) => !prev);
      }
      button.title = 'Icon tool';
      return this.container;
    }

    onRemove() {
      this.container.parentNode.removeChild(this.container);
      this.map = undefined;
    }
  }

  // Search
  const handleSearch = async () => {
    zoomToCounty(countyName)
    const results = await fetchCadastralData(countyName, ownerNameRef.current)
    fitBoundsToResults(results)
    setParcelFilters()
  }

  async function fetchCadastralData(countyStr, ownerStr) {
    setIsLoading(true)
    let maxResults = 250
    const response = await fetch(
      encodeURI(`https://services.arcgis.com/qnjIrwR8z5Izc0ij/arcgis/rest/services/Cadastral_Application_Support/FeatureServer/5/query?where=OwnerName LIKE '%${ownerStr}%' AND CountyName='${countyStr}'&outFields=CountyName,OwnerName,PARCELID&returnGeometry=true&returnZ=true&f=json&resultRecordCount=${maxResults}`),
      { mode: 'cors' }
    )
    const data = await response.json();
    setSearchResults(data.features);
    setIsLoading(false)
    return data.features;
  }

  function setParcelFilters() {
    const currentOwner = ownerNameRef.current
    if (map.current && map.current.getLayer(parcelsFillId)) {
      const excludedOwnersFilter = excludedParcelOwners.reduce((filter, currentOwner) => {
        return [...filter, ["!=", "OwnerName", currentOwner]]
      }, ["all", ["==", "CountyName", countyName], ["has", "OwnerName"]])

      map.current.setFilter('county-parcels-line', excludedOwnersFilter)
      map.current.setFilter('county-parcels-fill', excludedOwnersFilter)
      map.current.setFilter('parcel-results-line', excludedOwnersFilter)
      map.current.getLayer(parcelsLineId) && map.current.setFilter(parcelsLineId, ["==", "CountyName", countyName])

      if (currentOwner) {
        const parcelIDs = parcelsOwnedByOwner.map(parcel => parcel.attributes.PARCELID)
        const excludedOwnersFilter = excludedParcelOwners.reduce((filter, currentOwner) => {
          return [...filter, ["!=", "OwnerName", currentOwner]]
        }, ["all", ["==", "CountyName", countyName], ["has", "OwnerName"], ["in", "PARCELID", ...parcelIDs]])

        map.current.setFilter(parcelsFillId, excludedOwnersFilter)
        map.current.setFilter("parcel-results-line", excludedOwnersFilter)

        map.current.once('idle', function() {
          if (map.current.isSourceLoaded('montana-parcels')) {
            let sourceFeatures = map.current.querySourceFeatures('montana-parcels', { sourceLayer: 'montana-parcels' })
            sourceFeatures = sourceFeatures.filter(feature => {
              let parcelId = feature.properties.PARCELID
              if (parcelsOwnedByOwner.includes(parcelId)) return feature
            })

            if (sourceFeatures.length !== 0) {
                const featureCollection = turf.featureCollection(sourceFeatures) 
                const boundingBox = turf.bbox(featureCollection) 
                map.current.fitBounds(boundingBox, { padding: 50 })         
            }
          }
        })
      } else {
        map.current.setFilter(parcelsFillId, excludedOwnersFilter)
      }
    }
  }

  const fitBoundsToResults = (results) => {
    if (!results || results.length === 0) return

    const centroids = results.map((parcel) => {
      const polygon = turf.polygon(parcel.geometry.rings)
      const centroid = turf.centroid(polygon)
      return centroid
    })
  
    proj4.defs('EPSG:3857', '+proj=merc +a=6378137 +b=6378137 +lat_ts=0.0 +lon_0=0.0 +x_0=0.0 +y_0=0 +k=1.0 +units=m +nadgrids=@null +wktext +no_defs')
    let bbox = turf.bbox(turf.featureCollection(centroids))
    bbox = [
      proj4('EPSG:3857', 'EPSG:4326', [bbox[0], bbox[1]]),
      proj4('EPSG:3857', 'EPSG:4326', [bbox[2], bbox[3]])
    ].flat()
              
    map.current.fitBounds(bbox, { padding: 80, maxZoom: 17 })
        
    let zoomAdjusted = false
    map.current.on('moveend', () => {
      if (!zoomAdjusted) {
        const currentZoom = map.current.getZoom()
        if (currentZoom < 9) {
          zoomAdjusted = true
          map.current.easeTo({ zoom: 9, duration: 1000 })
        }
      }
    })

    results.map((parcel) => {
      parcelsOwnedByOwner.push(parcel)
    })
  }

  // Claiming
  const handleParcelClick = (parcel) => {
    removeSearchResult(parcel.parcelID || parcel.attributes?.PARCELID)

    const clickedParcelId = parcel.parcelID || parcel.attributes.PARCELID
    setClaimedParcels((prevState) => {
      if (prevState.some((existingParcel) => existingParcel.parcelID === clickedParcelId)) {
        let updatedParcels = prevState.filter(
          (existingParcel) => existingParcel.parcelID !== parcel.parcelID
        );
        let parcelIds = updatedParcels.map((parcel) => parcel.parcelID);

        if (map.current.getLayer(highlightedParcelsLineId)) {
          map.current.setFilter(highlightedParcelsLineId, [
            "in",
            "PARCELID",
            ...parcelIds,
          ]);
        }
        return updatedParcels;
      } else {
        let updatedParcels = [
          parcel,
          ...prevState
        ];
        
        let parcelIds = updatedParcels.map((parcel) => parcel.parcelID || parcel.attributes.PARCELID);
        
        if (map.current.getLayer(highlightedParcelsLineId)) {
          map.current.setFilter(highlightedParcelsLineId, [
            "in",
            "PARCELID",
            ...parcelIds,
          ]);
        }

        return updatedParcels;
      }
    });
  }

  const handleDeleteParcel = (parcel) => {
    restoreSearchResult(parcel.parcelID)

    setClaimedParcels((prevState) => {
      if (prevState.some((existingParcel) => existingParcel.parcelID === parcel.parcelID)) {
        let updatedParcels = prevState.filter((existingParcel) => existingParcel.parcelID !== parcel.parcelID)
        let parcelIds = updatedParcels.map((parcel) => parcel.parcelID)

        if (map.current.getLayer(highlightedParcelsLineId)) {
          map.current.setFilter(highlightedParcelsLineId, [
            "in",
            "PARCELID",
            ...parcelIds,
          ]);
        }
        return updatedParcels
      }
    }
  )}

  const handleClaimParcels = () => {
    if (claimedParcels.length === 0) return

    let parcelIDs = claimedParcels.map(parcel => parcel.parcelID)
    let renderedFeatures = map.current.queryRenderedFeatures({ layers: ['county-parcels-line'] })
    let matchingFeatures = renderedFeatures.filter(function(feature) {
      return parcelIDs.includes(feature.properties.PARCELID)
    })
    let multiPolygon = turf.multiPolygon(matchingFeatures.map(feature => feature.geometry.coordinates))
    let centroid = turf.centroid(multiPolygon)
    let boundingBox = turf.bbox(multiPolygon)
    setPropertyCentroid(centroid.geometry.coordinates)
    setPropertyBbox(boundingBox)
    setParcelsClaimed(true)
    ownershipConfirmed && setSidebarsVisible(false)
  }

  const toggleCollapse = () => {
    setIsMobileMenuCollapsed(!isMobileMenuCollapsed)
  }

  // General
  async function getParcelById(parcelId) {
    const response = await fetch(
      encodeURI(`https://services.arcgis.com/qnjIrwR8z5Izc0ij/arcgis/rest/services/Cadastral_Application_Support/FeatureServer/5/query?where=PARCELID='${parcelId}'&outFields=CountyName,OwnerName,PARCELID&returnGeometry=true&returnZ=true&f=json`),
      { mode: 'cors' }
    )
    const data = await response.json();
    const parcel = data.features[0]
    return parcel
  }

  async function getParcelCentroid(ID) {
    const parcel = await getParcelById(ID) 
    if (!parcel || !parcel.geometry) {
      return null;
    }
  
    const polygon = {
      "type": "Polygon",
      "coordinates": parcel?.geometry?.rings
    }

    try {
      const centroid = proj4('EPSG:3857', 'EPSG:4326', turf.centroid(polygon).geometry.coordinates);
      return centroid;
    } catch (error) {
      return null;
    }
  }

  async function zoomToParcel(ID) {
    const centroid = await getParcelCentroid(ID)
    centroid && map.current.jumpTo({
      center: centroid,
      zoom: 14,
    })
  }

  function zoomToCounty(countyName) {
    try {
      const feature = MT_centroids.features.find((f) => f.properties.NAME === countyName.toUpperCase())
      map.current?.jumpTo({ center: feature.geometry.coordinates, zoom: 9 })
    } catch (error) {
      console.error("Error fetching centroid data:", error);
    }
  }

  // Popups
  function closePopups() {
    popup && popup.remove()
    const popups = document.getElementsByClassName('mapboxgl-popup')
    while (popups.length > 0) popups[0].remove()
  }
  window.closePopups = closePopups

  const generatePopupHTML = function (ID) {
    const currentPropertyFeatures = propertyFeaturesRef.current
    const feature = currentPropertyFeatures.features.find(feature => feature.id === ID)

    const popupHTML = (`
      <div class="map-popup d-flex flex-column p-2 rounded shadow-sm">
        <button type="button" id="closeButton-${ID}" class="btn-close position-absolute top-0 end-0 m-2" onclick="closePopups()"></button>
        <div class="mb-3">
          <label class="form-label fs-6 m-0 ms-1 mb-2" for"labelInput-${ID}">Label</label>  
          <input class="form-control form-control-sm rounded" type="text" id="labelInput-${ID}" name="labelInput" placeholder="${(!feature?.properties?.label ? "Add a label..." : (feature?.properties?.label === "Click to add label..." ? "Add a label..." : feature?.properties?.label))}" value="${!feature?.properties?.label ? "" : (feature.properties.label === "Click to add label..." ? "" : feature.properties.label)}">
        </div>
        <div class="d-flex flex-wrap mb-3">
          <label class="form-label fs-6 w-100 m-0 ms-1 mb-2">Border Color</label>
          <div class="d-flex w-100 ml-0 justify-content-around">
            <button class="btn m-1 p-2" style="background-color: #FF0000; width: 33%; height: 30px; border-radius: 10%;" onclick="handleChangeColor('red', '${ID}')"></button>
            <button class="btn m-1 p-2" style="background-color: #00FF00; width: 33%; height: 30px; border-radius: 10%;" onclick="handleChangeColor('green', '${ID}')"></button>
            <button class="btn m-1 p-2" style="background-color: #0000FF; width: 33%; height: 30px; border-radius: 10%;" onclick="handleChangeColor('blue', '${ID}')"></button>
          </div>
          <div class="d-flex w-100 ml-0 justify-content-around">
            <button class="btn m-1 p-2" style="background-color: #FFFF00; width: 33%; height: 30px; border-radius: 10%;" onclick="handleChangeColor('yellow', '${ID}')"></button>
            <button class="btn m-1 p-2" style="background-color: #FFA500; width: 33%; height: 30px; border-radius: 10%;" onclick="handleChangeColor('orange', '${ID}')"></button>
            <button class="btn m-1 p-2" style="background-color: #800080; width: 33%; height: 30px; border-radius: 10%;" onclick="handleChangeColor('purple', '${ID}')"></button>
          </div>
        </div>
        ${!feature || !feature.geometry || feature.geometry.type !== 'Polygon' ? '' : `
          <div class="d-flex flex-row justify-content-between mb-3">
            <label class="form-label fs-6 m-0 ms-1 mb-2" for="polygonFillToggle-${ID}">Transparent</label>
            <input type="checkbox" class="form-check-input" id="polygonFillToggle-${ID}" ${feature.properties.polygonFill ? "" : "checked"} >
          </div>
        `}
        <div class="d-flex flex-row justify-content-between">
          <button class="btn btn-danger w-50 me-1" id="deleteButton-${ID}">Delete</button>
          <button class="btn btn-primary w-50 ms-1" id="saveButton-${ID}">Save</button>
        </div>
      </div>
    `)
      return popupHTML
  }

  const handlePopupDelete = function (ID) {
    const icon = document.getElementById(`${ID}`)
    icon && icon.remove()

    const featureIndex = propertyFeaturesRef.current.features.findIndex(feature => feature.id === ID)
    if (featureIndex !== -1) {
      let updatedFeatures = propertyFeaturesRef.current
      updatedFeatures.features.splice(featureIndex, 1)

      setPropertyFeatures(updatedFeatures)

      draw.current.get(ID) 
        && draw.current.delete(ID)
      map.current.getLayer(`label-${ID}`) 
        && map.current.removeLayer(`label-${ID}`)
    }

    closePopups()
  }

  const handlePopupSave = function (ID, labelInput) {  
    const labelValue = labelInput && labelInput.value !== 'Click to add label...' ? labelInput.value : '';

    const polygonFillInput = document.getElementById(`polygonFillToggle-${ID}`)
    const polygonFillValue = polygonFillInput && !polygonFillInput.checked ? true : false

    const featureIndex = propertyFeaturesRef.current.features.findIndex(feature => feature.id === ID)
    if (featureIndex === -1) console.error('Feature not found')

    let updatedFeatures = { ...propertyFeaturesRef.current }
    let updatedFeature = { ...updatedFeatures.features[featureIndex] }
    if (!updatedFeature.properties) updatedFeature.properties = {}

    updatedFeature.properties.color = drawingColorRef.current
    updatedFeature.properties.label = labelValue
    updatedFeature.properties.polygonFill = polygonFillValue

    if (!updatedFeature.properties.icon) updatedFeature.properties.icon = markerIconRef.current
 
    if (updatedFeature.properties.type === 'drawn-feature') {
      draw.current.delete(ID)
      draw.current.add(updatedFeature)
    }
  
    setPropertyFeatures(updatedFeatures)

    const labelSource = map.current.getSource(`label-${ID}`)

    if (labelSource) {
      labelSource.setData(updatedFeature);
      const styles = updatedFeature.geometry.type === 'LineString' ? lineLabelStyles : defaultLabelStyles;
      Object.keys(styles.layout).forEach(key => {
        map.current.setLayoutProperty(`label-${ID}`, key, styles.layout[key]);
      });
      Object.keys(styles.paint).forEach(key => {
        map.current.setPaintProperty(`label-${ID}`, key, styles.paint[key]);
      });
    }

    closePopups()
    }

  // Search Results
  const removeSearchResult = (parcelID) => {
    const removedItem = searchResultsRef.current.find(result => result.attributes.PARCELID === parcelID)
    if (removedItem) {
        setSearchResults(searchResultsRef.current.filter(result => result.attributes.PARCELID !== parcelID))
        setRemovedResults([...removedResults, removedItem])
    }
  }

  const restoreSearchResult = (parcelID) => {
    const restoredItem = removedResults.find(result => result.attributes.PARCELID === parcelID)
    if (restoredItem) {
      setSearchResults([restoredItem, ...searchResultsRef.current])
      setRemovedResults(removedResults.filter(result => result.attributes.PARCELID !== parcelID))
    }
  }

  let hoverPin = null
  async function handleOnHover(parcelID) {
    const centroid = await getParcelCentroid(parcelID)
    if (centroid) {
      hoverPin = new mapboxgl.Marker({color: "#FFD000"})
          .setLngLat(centroid)
          .addTo(map.current)
    }
  }

  function handleOffHover() {
    const markers = document.querySelectorAll('.mapboxgl-marker')
    markers.forEach(marker => marker.remove())
  }

  return (
    <div id="map-component" className="container-fluid px-0 h-100 overflow-none">
      <div className="h-100 w-100 rounded d-flex my-0">
        {/* Left Side panel */}
        {addingProperty && (
          <div 
            className="rounded-start mx-0 mb-1 p-2 px-2 d-flex flex-column align-items-center border bg-white overflow-none d-none d-md-flex col-md-3 h-100" 
          >
            {sidebarsVisible && (
              <>
                <div className="w-100 d-flex justify-content-between align-items-center">
                  <div className="flex-grow-1 text-center">
                    <h5 className="my-2">Search Results</h5>
                  </div>
                  <OverlayTrigger
                    placement="bottom"
                    overlay={infoTooltip("Click the parcel name to view it on the map, or use the green '+' to claim it and add it to your list.")}
                  >
                  <a className="text-gray-600">
                    <FontAwesomeIcon icon={faInfoCircle} />
                  </a>
                  </OverlayTrigger>
                </div>
                <ul className="list-group w-100 cursor-pointer h-75 overflow-auto">
                {searchResults.length === 0 ? (
                  <li className="d-flex justify-content-center text-center fw-bold pt-2">
                    No results found, try another search.
                  </li>
                ) : (
                  <div>
                    {ownerName === "" && (
                      <li className="d-flex flex-column text-center mt-3 text-muted">
                        {`Showing all parcels for ${countyName} county. Use owner name field to refine search`}    
                      </li>
                    )}

                    {ownerName !== "" && (
                      searchResults
                        .filter(result => !excludedParcelOwners.includes(result.attributes.OwnerName))
                        .slice(0, 30)
                        .sort((a, b) => a.attributes.OwnerName.localeCompare(b.attributes.OwnerName))
                        .map((result) => (
                      <li 
                        role="button"
                        className="list-group-item list-group-item-action rounded p-1 ps-2 mb-1 d-flex align-items-center"
                        key={result.attributes.PARCELID}
                        onClick={() => zoomToParcel(result.attributes.PARCELID)}
                        onMouseEnter={() => handleOnHover(result.attributes.PARCELID)}
                        onMouseLeave={() => handleOffHover()}
                      >
                        {result.attributes.OwnerName}
                        <span className="text-success ms-auto p-0">
                          <Button 
                            onClick={(e) => {
                              e.stopPropagation()
                              handleOffHover()
                              handleParcelClick({ parcelID: result.attributes.PARCELID, ownerName: result.attributes.OwnerName })
                            }}                        
                            className="px-2 py-0 fs-6 ms-auto"
                            variant="success"
                          >
                            +
                          </Button>
                        </span>
                      </li>
                    )))}

                    {ownerName !== "" && searchResults.length > 30 && (
                      <li className="d-flex flex-column text-center mt-3 text-muted">
                        More than 30 results found, try refining your search.
                      </li>
                    )}
                  </div>
                )}
                </ul>

                <div className="my-2"></div>                
                <div className="mt-auto w-100 border-top border-2 pt-2">
                  <h6 className="text-center mb-0">Search Criteria</h6>
                  <Form.Group className="w-100" >
                    <Form.Label className="d-block fs-6 form-label fw-medium m-0">State</Form.Label>
                    <Form.Control 
                      className="px-2 py-1 text-gray-600" 
                      as="select" 
                      value={stateName} 
                      disabled 
                      onChange={(e) => setStateName(e.target.value)}
                    >
                      <option value={"Montana"}>Montana</option>
                    </Form.Control>
                  </Form.Group>
                  <Form.Group className="w-100 mt-2">
                    <Form.Label className="d-block fs-6 form-label fw-medium m-0">County</Form.Label>
                    <Form.Control 
                      className="form-select px-2 py-1" 
                      as="select" 
                      value={countyName} 
                      onChange={(e) => setCountyName(e.target.value)}
                    >
                      {montanaCountyNames.map((county) => (
                        <option key={county} value={county}>
                          {county}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                  <Form.Group className="w-100 mt-2 position-relative">
                    <Form.Label 
                      className="d-block fs-6 form-label fw-medium m-0">Owner Name</Form.Label>
                    <InputGroup>
                      <Form.Control
                        type="text"
                        className="rounded px-2 py-1"
                        placeholder="Search..."
                        value={ownerName}
                        onChange={(e) => setOwnerName(e.target.value)}
                      />
                      {(
                        <Button
                          variant="danger"
                          className="position-absolute end-0 z-3 h-100 px-2 py-0"
                        >
                          <FontAwesomeIcon
                            icon={faTimes}
                            onClick={() => {
                              setOwnerName("")
                              handleSearch()
                            }}
                          />
                        </Button>
                      )}
                    </InputGroup>
                  </Form.Group>
                  <Button 
                    className="w-100 mt-3" 
                    onClick={handleSearch}
                    variant="primary"              
                  >
                    Search
                  </Button>
                </div>

              </>
            )}
          </div>
        )}

        {/* Map */}
        <div ref={mapContainer} 
          className={`map-container mx-0 m-0 position-relative col-12 ${addingProperty ? 'col-md-6' : 'col-md-12'} h-100`}>

          {isLoading && (
            <div className="z-1">
              <Preloader 
                logoSize={50} 
                show={isLoading} 
                fullscreen={true}
              />
            </div>
          )}

          {/* Mobile Claiming Menu */}
          {addingProperty && sidebarsVisible &&
          <div id="mobile-claiming-menu" className="rounded-top p-x pb-1 pt-1 bg-white w-100 position-absolute bottom-0 z-3 d-flex flex-column d-md-none">
            <div className="d-flex justify-content-between align-items-center">
              <h5 className="text-center m-2 w-100">Selected Parcels</h5>
              <Button
                variant="link"
                className="p-0"
                onClick={toggleCollapse}
              >
                <FontAwesomeIcon className="mx-2" icon={isMobileMenuCollapsed ? faChevronUp : faChevronDown} />
              </Button>
            </div>

            <Collapse in={!isMobileMenuCollapsed}>
              <div>
                {sidebarsVisible && (
                  <SelectedParcelsMenu 
                    claimedParcels={claimedParcels} 
                    handleDeleteParcel={handleDeleteParcel} 
                    handleClaimParcels={handleClaimParcels}
                    ownershipConfirmed={ownershipConfirmed}
                    setOwnershipConfirmed={setOwnershipConfirmed}
                    areParcelsClaimed={areParcelsClaimed}
                    setParcelsClaimed={setParcelsClaimed}  
                    zoomToParcel={zoomToParcel}
                    handleOnHover={handleOnHover}
                    handleOffHover={handleOffHover}
                    handleParcelClick={handleParcelClick}
                  />
                )}
              </div>
            </Collapse>            
          </div>
          }

          {addingProperty && 
            <ParcelSearch
              setOwnerName={setOwnerName}
              countyName={countyName}
              setCountyName={setCountyName}
              stateName={stateName}
              setStateName={setStateName}
              searchModalOpen={searchModalOpen}
              setSearchModalOpen={setSearchModalOpen}
              sidebarsVisible={sidebarsVisible}
              setSidebarsVisible={setSidebarsVisible}
              handleSearch={handleSearch}
            />
          }

          {addingProperty && 
            <ClaimParcels
              claimedParcels={claimedParcels}
              propertyName={propertyName}
              setPropertyName={setPropertyName}
              countyName={countyName}
              propertyCentroid={propertyCentroid}
              propertyBbox={propertyBbox}
              claimModalOpen={claimModalOpen}
            />
          }

          {(isDrawing) && 
            <DrawingTools 
              map={map} 
              draw={draw} 
              parcelsLineId={parcelsLineId}
              parcelsFillId={parcelsFillId}
              thisPropertyData={props.thisPropertyData}
              highlightedParcelsLineId={highlightedParcelsLineId}
              listingData={props.listingData}
              addingListing={addingListing}
              editingListing={editingListing}
              isAddingMarker={isAddingMarker}
              setIsAddingMarker={setIsAddingMarker}
              propertyFeatures={propertyFeatures}
              setPropertyFeatures={setPropertyFeatures}
              propertyFeaturesRef={propertyFeaturesRef}
              generatePopupHTML={generatePopupHTML}
              drawingColor={drawingColor}
              setDrawingColor={setDrawingColor}
              drawingColorRef={drawingColorRef}
              markerIcon={markerIcon}
              setMarkerIcon={setMarkerIcon}
              markerIconRef={markerIconRef}
              addPopup={addPopup}
              drawingMode={drawingMode}
            />
          }
        </div>

        {/* Right Side Panel */}
        {addingProperty && (
          <>
            <div className="rounded-end mx-0 p-2 px-2 d-none d-md-flex flex-column align-items-center border bg-white col-md-3">
              {sidebarsVisible && (
                <>
                  <div className="w-100 d-flex justify-content-between align-items-center">
                    <div className="flex-grow-1 text-center">
                      <h5 className="my-2">Search Results</h5>
                    </div>
                    <OverlayTrigger
                      placement="bottom"
                      overlay={infoTooltip("Click the red 'x' to remove the parcel from your list.")}
                    >
                    <a className="text-gray-600">
                      <FontAwesomeIcon icon={faInfoCircle} />
                    </a>
                    </OverlayTrigger>
                  </div>
                  <SelectedParcelsMenu 
                    claimedParcels={claimedParcels} 
                    handleDeleteParcel={handleDeleteParcel} 
                    handleClaimParcels={handleClaimParcels}
                    ownershipConfirmed={ownershipConfirmed}
                    setOwnershipConfirmed={setOwnershipConfirmed}
                    areParcelsClaimed={areParcelsClaimed}
                    setParcelsClaimed={setParcelsClaimed}
                    zoomToParcel={zoomToParcel}
                    handleOnHover={handleOnHover}
                    handleOffHover={handleOffHover}
                    handleParcelClick={handleParcelClick}
                  />
                </>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  )
}
export default Map
