import { Button, Form } from "react-bootstrap";

const SelectedParcelsMenu = (props) => {
    return (
        <div id="selected-parcels-menu" className="d-flex flex-column h-100">
            <div className="h-100 w-100 overflow-auto">
                <div className="w-100 overflow-scroll px-2">
                    <ul className="mx-0 px-0">
                        {props.claimedParcels.length === 0 && (
                            <p className="text-center fw-bold pt-2 h-0">
                                Use the map to select parcels for your property.
                            </p>
                        )}
                        {props.claimedParcels.map((parcel) => (
                            <li 
                                role="button"
                                className="list-group-item list-group-item-action rounded p-1 ps-2 mb-1 d-flex align-items-center"
                                key={parcel.parcelID}
                                onClick={() => props.zoomToParcel(parcel.parcelID)}
                                onMouseEnter={() => props.handleOnHover(parcel.parcelID)}
                                onMouseLeave={() => props.handleOffHover()}
                            >
                                {parcel.ownerName}
                                <span className="text-success ms-auto p-0">
                                <Button 
                                    onClick={(e) => {
                                    e.stopPropagation()
                                    props.handleOffHover()
                                    props.handleParcelClick({ parcelID: parcel.parcelID, ownerName: parcel.ownerName })
                                    }}                        
                                    className="px-2 py-0 fs-6 ms-auto"
                                    variant="danger"
                                >
                                    x
                                </Button>
                                </span>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
            <div className="mt-auto h-25">
                <div
                    className={`d-flex align-items-center justify-content-center pt-3 pb-2 ps-2 mb-1 rounded w-100 ${
                    !props.ownershipConfirmed && props.areParcelsClaimed ? "bg-danger text-white" : ""
                    }`}
                >
                    <div
                    onClick={(e) => {
                        props.setOwnershipConfirmed((prev) => !prev);
                        props.setParcelsClaimed(false);
                    }}
                    className="d-flex"
                    >
                        <Form.Check
                            type="checkbox"
                            checked={props.ownershipConfirmed}
                        />
                        <label className="px-2 fs-6">
                            I confirm that I own the selected properties.
                        </label>
                    </div>
                </div>

                <Button 
                    className="w-100" 
                    variant="primary"
                    onClick={() => props.handleClaimParcels()}
                >
                    Claim Selected Parcels
                </Button>
            </div>
        </div>
    )
}

export default SelectedParcelsMenu;