import React from "react";
import { Link } from "react-router-dom";

import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";

import moment from "moment-timezone";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle, faUserCircle, faMapMarkedAlt, faCalendarAlt, faRunning } from '@fortawesome/free-solid-svg-icons';

import { Col, Row, Card } from 'react-bootstrap';

import Preloader from "./Preloader";


const noDataIndication = (queryStatus) => {
  if ( queryStatus !== 200 && queryStatus !== 204 && queryStatus !== 401 && queryStatus !== 404 ) {
    return (<Preloader message="Loading..." logoSize={50} show={true} />);
  } else {
    return "None found.";
  }
}

export const PropertiesTable = (props) => {

  const queryStatus = props.queryStatus;

  // compile booking_count for each property
  for ( let iA = 0; iA < props.propertiesList.length; iA++ ) {
    props.propertiesList[iA].bookings_count = 0;
    if ( props.propertiesList[iA].listings.length ) {
      for ( let iB = 0; iB < props.propertiesList[iA].listings[iB].length; iB++ ) {
        props.propertiesList[iA].bookings_count = props.propertiesList[iA].listings[iB].bookings.length;
      }
    }
  }

  const columns = [
    {
      text: 'Property Title',
      dataField: 'title',
      sort: true,
      align: 'left',
      headerAlign: 'left',
      classes: 'title p-0',
      formatter: (cell, row, rowIndex, extraData) => (
        <Card.Link as={Link} to={"/dashboard/property/"+row._id} className="d-block px-2 px-lg-4 py-3">
          <FontAwesomeIcon icon={faMapMarkedAlt} size="lg" className="fa me-3" />
          <span className="fw-bolder">{row.title}</span>
        </Card.Link>
      )
    },
    {
      text: 'Listings',
      dataField: 'listings_count',
      sort: true,
      align: 'center',
      headerAlign: 'center',
      classes: 'listings_count p-0',
      formatter: (cell, row, rowIndex, extraData) => (
        <Card.Link as={Link} to={"/dashboard/property/"+row._id} className="d-block px-2 px-lg-4 py-3">
          {row.listings.length}
        </Card.Link>
      )
    },
    {
      text: 'Bookings',
      dataField: 'bookings_count',
      sort: true,
      align: 'center',
      headerAlign: 'center',
      classes: 'bookings_count p-0',
      formatter: (cell, row, rowIndex, extraData) => (
        <Card.Link as={Link} to={"/dashboard/property/"+row._id} className="d-block px-2 px-lg-4 py-3">
          {row.bookings_count}
        </Card.Link>
      )
    }
  ];
  const rowClasses = (row) => {
    return row.active === true ? 'active' : 'disabled text-gray-500';
  };
  const pagination = paginationFactory({
    page: 1,
    showTotal: true,
    hidePageListOnlyOnePage: true
  });
  const { SearchBar } = Search;

  return (
    <>
      <ToolkitProvider 
        keyField="_id"
        data={props.propertiesList}
        columns={columns}
        search
      >
        {
          (props) => (
                <>
                  <Row className="justify-content-end mb-3">
                    <Col md={8} className="d-flex align-items-center">
                      <h4 className="fs-5 m-0 ms-2">Properties</h4>
                    </Col>
                    <Col md={4}>
                      <SearchBar {...props.searchProps} placeholder="Search Properties" />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <BootstrapTable 
                        {...props.baseProps}
                        striped
                        rowClasses={rowClasses}
                        classes="properties-table"
                        pagination={pagination}
                        noDataIndication={noDataIndication(queryStatus)}
                      />
                    </Col>
                  </Row>
                </>
          )
        }
      </ToolkitProvider>
    </>
  );

}

export const ListingsTable = (props) => {

  const queryStatus = props.queryStatus;

  const columns = [
    {
      text: 'Listing Title',
      dataField: 'title',
      sort: true,
      align: 'left',
      headerAlign: 'left',
      classes: 'title p-0',
      formatter: (cell, row, rowIndex, extraData) => (
        <Card.Link as={Link} to={"/dashboard/listing/"+row._id} className="d-block px-2 px-lg-4 py-3">
          <FontAwesomeIcon icon={faRunning} size="lg" className="fa me-3" />
          <span className="fw-bolder">{row.title}</span>
        </Card.Link>
      )
    },
    {
      text: 'Bookings',
      dataField: 'bookings_count',
      sort: true,
      align: 'center',
      headerAlign: 'center',
      classes: 'bookings_count p-0',
      formatter: (cell, row, rowIndex, extraData) => (
        <Card.Link as={Link} to={"/dashboard/listing/"+row._id} className="d-block px-2 px-lg-4 py-3">
          {row.bookings.length}
        </Card.Link>
      )
    }
  ];
  const rowClasses = (row) => {
    return row.active === true ? 'active' : 'disabled text-gray-500';
  };
  const pagination = paginationFactory({
    page: 1,
    showTotal: true,
    hidePageListOnlyOnePage: true
  });
  const { SearchBar } = Search;

  return (
    <>
      <ToolkitProvider 
        keyField="_id"
        data={props.listingsList}
        columns={columns}
        search
      >
        {
          props => (
            <>
              <Row className="justify-content-end mb-3">
                <Col md={8} className="d-flex align-items-center justify-content-between mb-2">
                  <h4 className="fs-5 m-0 ms-2">Listings</h4>
                </Col>

                <Col md={4}>
                  <SearchBar {...props.searchProps} placeholder="Search Listings" />
                </Col>
              </Row>
              <Row>
                <Col>
                  <BootstrapTable 
                    {...props.baseProps}
                    striped
                    rowClasses={rowClasses}
                    classes="listings-table"
                    pagination={pagination}
                    noDataIndication={noDataIndication(queryStatus)}
                  />
                </Col>
              </Row>
            </>
          )
        }
      </ToolkitProvider>
    </>
  );

}

export const ListingsTablePublic = (props) => {

  const queryStatus = props.queryStatus;

  const columns = [
    {
      text: 'Listing Title',
      dataField: 'title',
      sort: true,
      align: 'left',
      headerAlign: 'left',
      classes: 'title p-0',
      formatter: (cell, row, rowIndex, extraData) => (
        <Card.Link as={Link} to={"/listing/"+row._id} className="d-block px-2 px-lg-4 py-3">
          <FontAwesomeIcon icon={faRunning} size="lg" className="fa me-3" />
          <span className="fw-bolder">{row.title}</span>
        </Card.Link>
      )
    }
  ];
  const rowClasses = (row) => {
    return row.active === true ? 'active' : 'disabled text-gray-500';
  };
  const pagination = paginationFactory({
    page: 1,
    showTotal: true,
    hidePageListOnlyOnePage: true
  });
  const { SearchBar } = Search;

  return (
    <ToolkitProvider 
      keyField="_id"
      data={props.listingsList}
      columns={columns}
      search
    >
      {
        props => (
          <>
            <Row className="justify-content-end mb-3">
              <Col md={8} className="d-flex align-items-center">
                <h4 className="m-0 ms-2">Listings</h4>
              </Col>
              <Col md={4}>
                <SearchBar {...props.searchProps} placeholder="Search Listings" />
              </Col>
            </Row>
            <Row>
              <Col>
                <BootstrapTable 
                  {...props.baseProps}
                  striped
                  rowClasses={rowClasses}
                  classes="listings-table"
                  pagination={pagination}
                  noDataIndication={noDataIndication(queryStatus)}
                />
              </Col>
            </Row>
          </>
        )
      }
    </ToolkitProvider>
  );

}

export const BookingsTable = (props) => {

  const queryStatus = props.queryStatus;
  const heading = props.heading;

  const columns = [
    {
      text: 'Booking Date',
      dataField: 'start',
      sort: true,
      align: 'left',
      headerAlign: 'left',
      classes: 'title p-0',
      formatter: (cell, row, rowIndex, extraData) => (
        <Card.Link as={Link} to={"/dashboard/booking/"+row._id} className="d-block px-2 px-lg-4 py-3">
          <FontAwesomeIcon icon={faCalendarAlt} size="lg" className="fa me-3" />
          <span className="fw-bolder">{moment(row.start).format('MM/DD/YYYY')}</span>
        </Card.Link>
      )
    },
    {
      text: 'Guest',
      dataField: 'guest_fullname',
      sort: true,
      align: 'center',
      headerAlign: 'center',
      classes: 'guest-name p-0',
      formatter: (cell, row, rowIndex, extraData) => (
        <Card.Link as={Link} to={"/dashboard/booking/"+row._id} className="d-block px-2 px-lg-4 py-3">
          {row.guest_fullname?row.guest_fullname:row.guest_email}
        </Card.Link>
      )
    },
    {
      text: 'Occupants',
      dataField: 'occupants',
      sort: true,
      align: 'center',
      headerAlign: 'center',
      classes: 'occupants p-0',
      formatter: (cell, row, rowIndex, extraData) => (
        <Card.Link as={Link} to={"/dashboard/booking/"+row._id} className="d-block px-2 px-lg-4 py-3">
          {row.occupants}
        </Card.Link>
      )
    },
    {
      text: 'Status',
      dataField: 'status',
      sort: true,
      align: 'center',
      headerAlign: 'center',
      classes: 'status p-0',
      formatter: (cell, row, rowIndex, extraData) => (
        <Card.Link as={Link} to={"/dashboard/booking/"+row._id} className="d-block px-2 px-lg-4 py-3">
          {row.status}
        </Card.Link>
      )
    },
    {
      text: 'Payment',
      dataField: 'transaction.status',
      sort: true,
      align: 'center',
      headerAlign: 'center',
      classes: 'payment p-0',
      formatter: (cell, row, rowIndex, extraData) => (
        <Card.Link as={Link} to={"/dashboard/booking/"+row._id} className="d-block px-2 px-lg-4 py-3">
          {(row.hasOwnProperty('refund') && row.refund.status === 'succeeded') && 'Refunded'}
          {(row.hasOwnProperty('transaction') && !row.hasOwnProperty('refund')) && row.transaction.status}
          {(!row.hasOwnProperty('transaction') && !row.hasOwnProperty('refund')) && 'Required'}
        </Card.Link>
      )
    }
  ];
  const rowClasses = (row) => {
    return 'status-'+row.status+' payment-'+(row.hasOwnProperty('transaction')?row.transaction.status:'required')+' '+(row.hasOwnProperty('refund') && 'refund-'+row.refund.status);
  };
  const pagination = paginationFactory({
    page: 1,
    showTotal: true,
    hidePageListOnlyOnePage: true
  });
  const { SearchBar } = Search;

  return (
    <Card border="light" className="table-wrapper table-responsive mb-4">
      <Card.Body>
        <ToolkitProvider 
          keyField="_id"
          data={props.bookingsList}
          columns={columns}
          search
        >
          {
            props => (
              <>
                <Row className="justify-content-end mb-3">
                  <Col md={8} className="d-flex align-items-center">
                    <h4 className="m-0 ms-2">{heading ? heading : 'Bookings'}</h4>
                  </Col>
                  <Col md={4}>
                    <SearchBar {...props.searchProps} placeholder="Search Bookings" />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <BootstrapTable 
                      {...props.baseProps}
                      striped
                      rowClasses={rowClasses}
                      classes="bookings-table"
                      pagination={pagination}
                      noDataIndication={noDataIndication(queryStatus)}
                    />
                  </Col>
                </Row>
              </>
            )
          }
        </ToolkitProvider>
      </Card.Body>
    </Card>
  );

}

export const UsersTable = (props) => {

  const queryStatus = props.queryStatus;

  const columns = [
    {
      text: 'Name',
      dataField: 'name',
      sort: true,
      align: 'left',
      headerAlign: 'left',
      classes: 'name p-0',
      formatter: (cell, row, rowIndex, extraData) => (
        <Card.Link as={Link} to={"/dashboard/user/"+row._id} className="d-block text-black px-2 px-lg-4 py-3">
          <FontAwesomeIcon icon={faUserCircle} size="lg" className="fa me-3" />
          <span className="fw-bolder">{row.firstName} {row.lastName}</span>
        </Card.Link>
      )
    },
    {
      text: 'Email',
      dataField: 'email',
      sort: true,
      align: 'center',
      headerAlign: 'center',
      classes: 'email p-0',
      formatter: (cell, row, rowIndex, extraData) => (
        <Card.Link as={Link} to={"/dashboard/user/"+row._id} className="d-block text-black px-md-2 px-lg-4 py-3">
          {row.email}
        </Card.Link>
      )
    },
    {
      text: 'Role',
      dataField: 'role',
      sort: true,
      align: 'center',
      headerAlign: 'center',
      headerClasses: 'px-0 px-md-4 d-none d-sm-table-cell',
      classes: 'role p-0 d-none d-sm-table-cell',
      formatter: (cell, row, rowIndex, extraData) => (
        <Card.Link as={Link} to={"/dashboard/user/"+row._id} className="d-block px-md-2 px-lg-4 py-3">
          {row.hasOwnProperty('roleNames') && row.roleNames.length ? row.roleNames[0].replace('ROLE_', '').toLowerCase() : 'user'}
        </Card.Link>
      )
    },
    {
      text: 'Listings',
      dataField: 'listingsCount',
      sort: true,
      align: 'center',
      headerAlign: 'center',
      headerClasses: 'px-0 px-md-4',
      classes: 'listings_count p-0',
      formatter: (cell, row, rowIndex, extraData) => (
        <Card.Link as={Link} to={"/dashboard/user/"+row._id} className="d-block text-black px-md-2 px-lg-4 py-3">
          {row.hasOwnProperty('listingsCount') && row.listingsCount === null
            ?
              '\u2013'
            :
              row.listingsCount
          }
        </Card.Link>
      )
    },
    {
      text: 'Bookings',
      dataField: 'bookingsCount',
      sort: true,
      align: 'center',
      headerAlign: 'center',
      headerClasses: 'px-0 px-md-4',
      classes: 'bookings_count p-0',
      formatter: (cell, row, rowIndex, extraData) => (
        <Card.Link as={Link} to={"/dashboard/user/"+row._id} className="d-block text-black px-md-2 px-lg-4 py-3">
          {row.hasOwnProperty('bookingsCount') && row.bookingsCount === null
            ?
              '\u2013'
            :
              row.bookingsCount
          }
        </Card.Link>
      )
    },
    {
      text: 'Remove',
      dataField: '',
      align: 'center',
      headerAlign: 'center',
      headerClasses: 'd-none d-md-table-cell text-danger',
      classes: 'remove text-danger px-2 px-lg-4 py-3 d-none d-md-table-cell',
      formatter: (cell, row, rowIndex, extraData) => (
        <Card.Link title="Remove User" onClick={() => props.removeUser(row._id)} className={row.role==='admin'?'d-none':null}>
          <FontAwesomeIcon icon={faTimesCircle} size="lg" />
        </Card.Link>
      )
    }
  ];
  const rowClasses = (row) => {
    return (row.hasOwnProperty('roleNames') && row.roleNames.length) ? row.roleNames[0] : 'ROLE_USER';
  };
  const pagination = paginationFactory({
    page: 1,
    showTotal: true,
    hidePageListOnlyOnePage: true,
    sizePerPage: 25,
    sizePerPageList: [
        { text: '25', value: 25},
        { text: '50', value: 50},
        { text: 'All', value: props.usersList.length}
      ]
  });
/*
  const defaultSorted = [{
    dataField: 'role',
    order: 'desc'
  }];
*/
  const { SearchBar } = Search;

  return (
    <Card border="light" className="table-wrapper table-responsive mb-4">
      <Card.Body>
        <ToolkitProvider 
          keyField="_id"
          data={props.usersList}
          columns={columns}
          search
        >
          {
            props => (
              <>
                <Row className="justify-content-end mb-3">
                  <Col md={8} className="d-flex align-items-center">
                    <h4 className="m-0 ms-2">Users</h4>
                  </Col>
                  <Col md={4}>
                    <SearchBar {...props.searchProps} placeholder="Search Users" />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <BootstrapTable 
                      {...props.baseProps}
                      striped
                      rowClasses={rowClasses}
                      classes="users-table"
                      pagination={pagination}
                      // defaultSorted={defaultSorted}
                      noDataIndication={noDataIndication(queryStatus)}
                    />
                  </Col>
                </Row>
              </>
            )
          }
        </ToolkitProvider>
      </Card.Body>
    </Card>
  );

}
