import { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import Cookies from "js-cookie";
import { Form, Button } from "react-bootstrap";

import PropertyService from "../../services/property.service";
import MapTooltip from "../map/MapTooltip";

const ClaimParcels = (props) => {
  const history = useHistory();

  let currentUserData = Cookies.get('authToken');
  currentUserData = currentUserData ? JSON.parse(currentUserData).data : null;
  let authToken = currentUserData.accessToken;

  const thisUserID = useParams()

  async function createProperty(parcels) {
    let index = parcels.findIndex(parcel => parcel.street_address !== undefined)
    let data = {
      'host': thisUserID.id?thisUserID.id:currentUserData.id,
      'title': props.propertyName,
      'address': {
        'street_address': parcels[index]?.street_address || null,
        'city': parcels[index]?.city || null,
        'county': props.countyName || null,
        'state': parcels[index]?.state || null,
        'zipcode': parcels[index]?.zipcode || null
      },
      'parcels': props.claimedParcels.map(parcel => parcel.parcelID),
      'property_centroid': props.propertyCentroid,
      'property_bbox': props.propertyBbox,
    }

    PropertyService.addProperty(data, authToken)
    .then(res => {
      history.push(`/dashboard/property/${res.data._id}`);
    })
    .catch(err => {
      // console.error(err);
    });

    localStorage.setItem("props.claimedParcels", JSON.stringify(parcels));
  }

  const [submitted, setSubmitted] = useState(false)
  const [newPropertyName, setNewPropertyName] = useState('')

  const handleSubmit = (e) => {
    e.preventDefault()
    e.stopPropagation()
    setSubmitted(true)
  
    if (newPropertyName !== "") createProperty(props.claimedParcels)
  }
  
  return (
    <>
      <div id="claim-parcels" className={`bg-gray-100 h-100 w-100 d-flex justify-content-center z-5 position-absolute ${props.claimModalOpen ? "d-block" : "d-none"}`} >
        <div className="h-100 d-flex justify-content-center align-items-center flex-column text-center">
          <h2 className="text-primary fw-bolder mb-4 fs-5 w-75">
            {`You've selected ${props.claimedParcels.length === 1 ? "1 parcel" : `${props.claimedParcels.length} parcels`} for this property. Please provide a
              property name.`}
          </h2>
          <Form noValidate onSubmit={handleSubmit} className="w-75 mt-2">
            <Form.Group>
              <Form.Control
                className="text-center"
                placeholder={"e.g., Silver Creek Ranch"}
                onChange={(e) => {
                  setNewPropertyName(e.target.value)
                  props.setPropertyName(e.target.value)
                }}                
                required
                isInvalid={submitted && newPropertyName === ''}
              />
              <Form.Control.Feedback type="invalid">
                Please enter a property name.
              </Form.Control.Feedback>
            </Form.Group>

            <Button 
              variant="outline-primary"
              type="submit"
              className="my-3 px-5"
            >
              Submit
            </Button>
          </Form>
          <div className="text-muted fs-6 w-75">
            Click submit to save your property.
          </div>
        </div>
      </div>
    <MapTooltip text={"Click to select one or more parcels, then click Submit to create your property."}/>
    </>
  );
}
export default ClaimParcels;
