import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Routes } from "../../routes";
import _ from "lodash";
import Cookies from "js-cookie";
import moment from "moment-timezone";

import AuthService from "../../services/auth.service";
import UserService from "../../services/user.service";
import PropertyService from "../../services/property.service";

import { UsersTable } from "../../components/Tables";
import { RegisterUser } from "../../components/Forms";
import Map from "../map/Map";
import { AddListing } from "../../components/forms/addListing";

import Preloader from "../../components/Preloader";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserPlus, faPlusCircle, faChartPie, faRunning } from '@fortawesome/free-solid-svg-icons';

import { Modal, Col, Row, Button, ButtonGroup, Card, Badge } from 'react-bootstrap';


const DashboardOverview = (props) => {
  document.title = "Dashboard | REC-X";

  let currentUserData = Cookies.get('authToken');
  currentUserData = currentUserData ? JSON.parse(currentUserData).data : null;
  let authToken = currentUserData.accessToken;

  const [queryStatusUsersList, setQueryStatusUsersList] = useState(false);
  const [usersList, setUsersList] = useState([]);

  useEffect(() => {
    if ( !queryStatusUsersList && currentUserData && currentUserData.roles.includes('ROLE_ADMIN') ) {
      UserService.getUsersList(currentUserData.accessToken)
        .then(users => {
          setQueryStatusUsersList(users.status);

          // sort users list
          if ( users.body && users.body.length > 0 ) {
            let sortedRes = _.orderBy(users.body, [/*'roleNames[0]',*/ 'firstName', 'email'], [/*'desc',*/ 'asc', 'asc']);
            setUsersList(sortedRes);
          }
        });
    }
  }, [currentUserData]);



  const [isOpen, setIsOpen] = useState(false);
  const showModal = () => {
    setIsOpen(true);
  };
  const hideModal = () => {
    setIsOpen(false);
  };


  let registerUserFormData = {};
  const doRegister = (e) => {
    if ( currentUserData && currentUserData.roles.includes('ROLE_ADMIN') ) {
      AuthService.register(registerUserFormData, authToken)
        .then(res => {
          if ( res.status === 200 ) {
            let updatedUsersList = _.concat(registerUserFormData, usersList);
            setUsersList(updatedUsersList);
            hideModal();
          } else { // API Error
            window.alert('Error '+res.status+'\r\nUser could not be added.');
          }
        },
        err => {
          console.log(err);
        });
    }
  }


  const [queryStatusUser, setQueryStatusUser] = useState(false);
  const [propertiesList, setPropertiesList] = useState([]);
  const [listingsList, setListingsList] = useState([]);
  const [bookingsList, setBookingsList] = useState([]);
  useEffect(() => {
      if ( !queryStatusUser && currentUserData && currentUserData.roles.includes('ROLE_USER') ) {
        UserService.getUser(authToken)
          .then((res) => {
            setQueryStatusUser(res.status);
            if ( res.status === 200 ) {
              // set Properties data
              let sortedRes = _.orderBy(res.body.properties, ['active', 'title'], ['desc', 'asc']);
              setPropertiesList(sortedRes);
// console.log(sortedRes)
              // compile Listings Data
              let listings = [];
              // compile Bookings Data
              let bookings = [];
              // loop properties
              for ( let iA = 0; iA < res.body.properties.length; iA++ ) {
                if ( res.body.properties[iA].listings.length ) {
                  // loop those listings
                  for ( let iB = 0; iB < res.body.properties[iA].listings.length; iB++ ) {
                    if ( res.body.properties[iA].listings[iB].bookings.length ) {
                      // loop those bookings
                      for ( let iC = 0; iC < res.body.properties[iA].listings[iB].bookings.length; iC++  ) {
                        if ( Object.keys(res.body.properties[iA].listings[iB].bookings[iC]).length ) {
                          // compile individual bookings
                          res.body.properties[iA].listings[iB].bookings[iC]['propertyData'] = {title: res.body.properties[iA].title};
                          res.body.properties[iA].listings[iB].bookings[iC]['listingData'] = {title: res.body.properties[iA].listings[iB].title, gallery: res.body.properties[iA].listings[iB].gallery};
                          bookings.push(res.body.properties[iA].listings[iB].bookings[iC]);
                        }
                      }
                    }
                    // compile individual listings
                    res.body.properties[iA].listings[iB]['propertyData'] = {title: res.body.properties[iA].title};
                    listings.push(res.body.properties[iA].listings[iB]);
                  }
                }
              }

              //set Listings data
              setListingsList(listings);

              // exclude old bookings
              bookings = _.filter(bookings, function(b) { // Filter out past Bookings beyond ~2 days before today
                if ( b.start >= new Date(Date.now() - 1728e5).toISOString() ) return b;
              });
              // sort bookings
              bookings = _.orderBy(bookings, ['start', 'status'], ['asc', 'asc']);
              // set Bookings data
              setBookingsList(bookings);
            }
          })
          .catch((err) => console.log(err));
      }
  }, [currentUserData]);


  const [addListingModalOpen, setAddListingModalOpen] = useState(false);
  const [addPropertyModalOpen, setAddPropertyModalOpen] = useState(false);
  const [propertyIDforNewListing, setPropertyIDforNewListing] = useState(null);
  useEffect(() => {
    if ( propertiesList.length > 0 ) setPropertyIDforNewListing(propertiesList[0]._id);
  }, [propertiesList]);

  const addListing = (e) => {
    e.preventDefault();

    setAddListingModalOpen(false);
    setDrawNewListingModalOpen(true);
  }

  const [drawNewListingModalOpen, setDrawNewListingModalOpen] = useState(false);


  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const addProperty = (e) => {
    let data = {
      'title': title,
      'description': description,
      'host': currentUserData.id
    };

    PropertyService.addProperty(data, authToken)
      .then(res => {
        props.history.push("/dashboard/property/"+res.data._id);
      },
      err => {
        // console.log(err);
      });
  }

  if ( currentUserData && currentUserData.roles.includes('ROLE_ADMIN') ) {

    const removeUser = (id) => {
      const thisUser = usersList.find( user => user._id === id );
      if ( window.confirm("Remove user "+thisUser.email+"?") ) {
        UserService.removeUser(id, authToken)
          .then(res => {
            alert("User " + thisUser.email + " was successfully removed. All Properties, Listings, and Bookings associated with this User have been removed.");
            setUsersList( usersList.filter( user => user._id !== id ) );
          })
          .catch(err => {
            alert(err.response.data.message ? err.response.data.message : "User " + thisUser.email + " was not removed.");
          });
      }
    }

    return (
      <>
        <Row className="justify-content-center mb-4">
          <Col md={10} xl={6}>
            <Card>
              <Card.Body className="d-flex flex-wrap justify-content-between align-items-center" style={{minHeight:'84px'}}>
                <h5 className="mb-0">
                  <FontAwesomeIcon icon={faChartPie} className="me-2" />
                  Admin Dashboard
                </h5>

                <ButtonGroup>
                  <Button variant="outline-primary" onClick={showModal}>
                    <FontAwesomeIcon icon={faUserPlus} className="me-2" /> Add User
                  </Button>
                </ButtonGroup>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col className="mb-4">

            <UsersTable queryStatus={queryStatusUsersList} usersList={usersList} removeUser={(id) => removeUser(id)} />

          </Col>
        </Row>

        <Modal show={isOpen} onHide={hideModal}>
          <Modal.Header closeButton>
            <Modal.Title>
              <FontAwesomeIcon icon={faUserPlus} className="me-2" /> Add User
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <RegisterUser doRegister={() => doRegister()} formData={registerUserFormData} />
          </Modal.Body>
        </Modal>
      </>
    );

  } else if ( currentUserData && currentUserData.roles.includes('ROLE_USER') ) { // TODO : unneeded conditional?

    if ( queryStatusUser ) { // show Preloader while data loads
      return (
        <>

          {bookingsList && bookingsList.length > 0 &&
            <>
              <Row>
                <Col className="my-4">

                  <h2 className="fs-4 text-dark text-decoration-underline ms-3 mb-4">My Upcoming Bookings</h2>

                  <Row className="row-cols-2 row-cols-md-4 row-cols-lg-5 row-cols-xl-6 g-3">
                    { bookingsList.map( (booking) => {
                      return (
                        <Col key={booking._id}>
                          <Card as="a" href={`/dashboard/booking/${booking._id}`} className="h-100">
                            <Badge bg={['pending', 'cancelled', 'declined'].includes(booking.status)?'warning':'success'} className="text-uppercase position-absolute top-0 end-0 mt-n2 me-n2 px-3 py-2">{booking.status}</Badge>
                            <Card.Img variant="top" src={booking.listingData.gallery[0]?booking.listingData.gallery[0]:`https://picsum.photos/600/500?${Math.floor(Math.random()*100)}`} />
                            <Card.Body>
                              <Card.Title>{booking.listingData.title}</Card.Title>
                              <Card.Text>{booking.guestData.firstName} {booking.guestData.lastName} on {moment(booking.start).format('MM/DD/YYYY')}</Card.Text>
                            </Card.Body>
                          </Card>
                        </Col>
                      )
                    }) }
                  </Row>

                </Col>
              </Row>

              <hr />
            </>
          }


          { propertiesList.length > 0 &&
            <>
              <Row>
                <Col className="my-4">

                  <h2 className="fs-4 text-dark text-decoration-underline ms-3 mb-4">My Listings</h2>

                  {listingsList &&
                    <Row className="row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-5 g-3">
                      { listingsList.map( (listing) => {
                        return (
                          <Col key={listing._id}>
                            <Card as="a" href={`/dashboard/listing/${listing._id}`} className="h-100 position-relative">
                              {!listing.active &&
                                <Badge bg="warning" className="text-uppercase position-absolute top-0 end-0 mt-n2 me-n2 px-3 py-2">Inactive</Badge>
                              }
                              <Card.Img variant="top" src={listing.gallery[0]?listing.gallery[0]:`https://picsum.photos/600/500?${Math.floor(Math.random()*100)}`} />
                              <Card.Body>
                                <Card.Title>{listing.title}</Card.Title>
                                <Card.Text>{listing.propertyData.title}</Card.Text>
                              </Card.Body>
                            </Card>
                          </Col>
                        )
                      }) }

                      <Col>
                        <Card 
                          role="button" 
                          onClick={() => setAddListingModalOpen(true)} 
                          className="h-100 align-items-center justify-content-center py-5"
                        >
                          <FontAwesomeIcon icon={faPlusCircle} className="fa-2x text-secondary" />
                          <h3 className="fs-5 mt-3">Add New Listing</h3>
                        </Card>
                      </Col>
                    </Row>
                  }

                </Col>
              </Row>

              <Modal show={addListingModalOpen} onHide={() => setAddListingModalOpen(false)}>
                <Modal.Header closeButton>
                  <Modal.Title>
                    <FontAwesomeIcon icon={faPlusCircle} className="me-2" /> New Listing
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <AddListing propertiesList={propertiesList} setPropertyIDforNewListing={setPropertyIDforNewListing} addListing={addListing} />
                </Modal.Body>
              </Modal>

              <Modal show={drawNewListingModalOpen} onHide={() => setDrawNewListingModalOpen(false)} size="fullscreen" keyboard={false}>
                <Modal.Header closeButton>
                  <Modal.Title>
                    <FontAwesomeIcon icon={faRunning} className="me-2" /> New Listing
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Map mapMode={"addListing"} thisPropertyData={_.find( propertiesList, (p) => {return p._id === propertyIDforNewListing} )} />
                </Modal.Body>
              </Modal>

              <hr />
            </>
          }


          <Row>
            <Col className="my-4">

              <h2 className="fs-4 text-dark text-decoration-underline ms-3 mb-4">My Properties</h2>

              {propertiesList &&
                <Row className="row-cols-1 row-cols-sm-2 row-cols-lg-3 row-cols-xl-4 g-3">
                  {
                    propertiesList.map( (property) => {
                      return (
                        <Col key={property._id}>
                          <Card as="a" href={`/dashboard/property/${property._id}`} className="h-100">
                            {!property.listings.some(o => o['active']) &&
                              <Badge bg="warning" className="text-uppercase position-absolute top-0 end-0 mt-n2 me-n2 px-3 py-2">No Active Listings</Badge>
                            }
                            <Card.Img variant="top" src={property.gallery[0]?property.gallery[0]:`https://picsum.photos/600/500?${Math.floor(Math.random()*100)}`} />
                            <Card.Body>
                              <Card.Title>{property.title}</Card.Title>
                            </Card.Body>
                          </Card>
                        </Col>
                      )
                    })
                  }

                  <Col>
                    <Card 
                      role="button" 
                      onClick={() => setAddPropertyModalOpen(true)} 
                      className="h-100 align-items-center justify-content-center py-5"
                    >
                      <FontAwesomeIcon icon={faPlusCircle} className="fa-2x text-secondary" />
                      <h3 className="fs-5 mt-3">Add New Property</h3>
                    </Card>
                  </Col>
                </Row>
              }

            </Col>
          </Row>

          <Modal show={addPropertyModalOpen} onHide={() => setAddPropertyModalOpen(false)} fullscreen keyboard={false}>
            <Modal.Header closeButton>
              <Modal.Title>
                <FontAwesomeIcon icon={faPlusCircle} className="me-2" /> Add Property
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="bg-gray-200 py-2 px-1">
              <Map mapMode={"addProperty"}/>
            </Modal.Body>
          </Modal>

        </>
      );
    } else {
      return (
        <div className="mt-5" style={{height: '40vh'}}>
          <Preloader message="Loading..." logoSize={50} show={true} />
        </div>
      );
    }

  }

};
export default DashboardOverview;
