import React, { useState, useEffect } from "react";
import moment from "moment-timezone";
import Datetime from "react-datetime";
import _ from "lodash";

import { statesList } from "../../components/data/statesList";
import { masterCategoryDefinitions, listingTypesDefinitions } from '../../listingTypes/listingTypes';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt, faSearch } from "@fortawesome/free-solid-svg-icons";

import { Col, Row, Form, Button, InputGroup } from "react-bootstrap";


export const AdvancedSearchListings = (props) => {

  const [highlightSubmitButton, setHighlightSubmitButton] = useState(false);

  const [searchListingsFormData, setSearchListingsFormData] = useState({});
  useEffect(() => {
    setSearchListingsFormData(props.formData);
  }, [props.formData]);

  useEffect(() => {
    let formattedData = { ...props.formData };
    if (typeof formattedData.category === 'string') formattedData.category = [formattedData.category];
    props.setNewSearchFormData(formattedData);
  }, []);

  function setFormValues(e) {
    // update state of searchListingsFormData
    let currentSearchListingsFormData = props.newSearchFormData;
    currentSearchListingsFormData[e.target.name] = e.target.value;
    props.setNewSearchFormData(currentSearchListingsFormData);
    setHighlightSubmitButton(true);
  }


  const [searchState, setSearchState] = useState(0);
  useEffect(() => {
    setSearchState(props.formData.state);
  }, [props.formData.state]);
  function setStateValue(e) {
    let currentSearchListingsFormData = searchListingsFormData;
    if ( Object.values(statesList).includes(e.target.value) ) {
      currentSearchListingsFormData[e.target.name] = e.target.value;
    } else {
      delete currentSearchListingsFormData[e.target.name];
    }
    setSearchListingsFormData(currentSearchListingsFormData);
    setHighlightSubmitButton(true);

    setSearchState(e.target.value);
  }

/*
  const [activityFilterValues, setActivityFilterValues] = useState([]);
  useEffect(() => {
    setActivityFilterValues(props.formData.activity);
  }, [props.formData.activity]);

  function setActivityFilter(e) {
    const checkedState = e.target.checked;
    // store state into local
    let currentSearchListingsFormData = searchListingsFormData?searchListingsFormData:{};

    // if only 1 'activity' parsed from URL, convert to an array with that single value, then add subsequent values
    if ( typeof currentSearchListingsFormData['activity'] === 'string' ) {
      currentSearchListingsFormData['activity'] = [currentSearchListingsFormData['activity']];
      setActivityFilterValues([currentSearchListingsFormData['activity']]);
    }
    // if no 'activity' filters set, create array
    if ( !currentSearchListingsFormData.hasOwnProperty('activity') ) {
      currentSearchListingsFormData['activity'] = [];
    }

    if ( !currentSearchListingsFormData['activity'].includes(e.target.value) && checkedState ) {
      // Add new 'activity' value to array
      currentSearchListingsFormData['activity'] = _.concat(currentSearchListingsFormData['activity'], e.target.value);
      setActivityFilterValues(_.concat(currentSearchListingsFormData['activity'], e.target.value));
    } else {
      // Remove 'activity' from array if present
      currentSearchListingsFormData['activity'] = currentSearchListingsFormData['activity'].filter(el => el !== e.target.value);
      setActivityFilterValues(currentSearchListingsFormData['activity'].filter(el => el !== e.target.value));
    }

    setSearchListingsFormData(currentSearchListingsFormData);
    setHighlightSubmitButton(true);
  }
*/


  const [dateRangeStart, setDateRangeStart] = useState(0);
  useEffect(() => {
    setDateRangeStart(props.newSearchFormData.start || "");
  }, [props.newSearchFormData]);
  const changeDateRangeStart = (e) => {
    let currentSearchListingsFormData = props.newSearchFormData ? props.newSearchFormData : {};
    setDateRangeStart(e.format('MM-DD-YYYY'));
    currentSearchListingsFormData['start'] = e.format('MM-DD-YYYY');
    props.setNewSearchFormData(currentSearchListingsFormData);
    setHighlightSubmitButton(true);

    // set next day after dateRangeStart
    changeDateRangeEnd(moment(e).add(1, 'day'));

    // auto-click End Date field
    document.querySelector('#dateRangeEnd input').focus();
  };

  const [dateRangeEnd, setDateRangeEnd] = useState(0);
  useEffect(() => {
    setDateRangeEnd(props.newSearchFormData.end || "");
  }, [props.newSearchFormData]);
  const changeDateRangeEnd = (e) => {
    let currentSearchListingsFormData = props.newSearchFormData ? props.newSearchFormData : {};
    setDateRangeEnd(e.format('MM-DD-YYYY'));
    currentSearchListingsFormData['end'] = e.format('MM-DD-YYYY');
    props.setNewSearchFormData(currentSearchListingsFormData);
    setHighlightSubmitButton(true);
  };

  var isValidEndDate = function(current) {
    // check date is NOT BEFORE user-selected start
    if ( dateRangeStart ) {
      if ( !current.isAfter( moment(dateRangeStart).add(-1, 'day') ) ) { return false; }
    }

    // check date is NOT BEFORE TODAY
    if ( !current.isAfter( moment().add(-1, 'day') ) ) { return false; }

    return true;
  };


//   useEffect(() => {
//     if ( searchListingsFormData && Object.keys(searchListingsFormData).length > 0 ) {

//       // set initial category selection based on URL
//       if ( searchListingsFormData.hasOwnProperty('category') ) {
//         document.getElementById(searchListingsFormData.category+'-category-wrapper').click();
//       }

//       // set initial activity selection based on URL
//       if ( searchListingsFormData.hasOwnProperty('activity') ) {
//         // when other parameter included in URL, eg. searchText, parser stringifies array into first array element - check, then split if that happens
//         let activites = ( searchListingsFormData.activity.length == 1 ) ? searchListingsFormData.activity[0].split(',') : searchListingsFormData.activity;

//         for ( let i=0; activites.length > i; i++ ) {

//           // mark selected activities checked
//          document.getElementById(activites[i]+'-activity-wrapper').click();
// /*
//           // toggle display of listingType specifications for selected activity
//           if ( listingTypesDefinitions[activites[i]].hasOwnProperty('fields') && Object.keys(listingTypesDefinitions[activites[i]].fields).length > 0 ) {
//             document.getElementById('listingTypeFilter-'+activites[i]).classList.toggle('d-none');
//           }
// */
//         }
//       }

//     }
//   }, [searchListingsFormData]);

  const setCategoryFilter = (c, isChecked) => {
    let updatedSearchFormData = (Object.keys(props.newSearchFormData).length > 0) ? { ...props.newSearchFormData } : {};
    updatedSearchFormData.category = [];
    updatedSearchFormData.activity = [];

    if (isChecked) {
      // add category if checked
      if (!updatedSearchFormData.category || updatedSearchFormData.category.length === 0) {
        updatedSearchFormData.category = [c.id];
      } else {
        updatedSearchFormData.category = 
          Array.isArray(updatedSearchFormData.category) && !updatedSearchFormData.category.includes(c.id)
            ? [...updatedSearchFormData.category, c.id] 
            : [updatedSearchFormData.category, c.id]
      }
    } else {
        // remove category if unchecked
        updatedSearchFormData.category = updatedSearchFormData.category.filter((cat) => cat !== c.id);
    }

    c.listingTypes.forEach((a) => {
      if (isChecked) {
        // add category activities
        if (!updatedSearchFormData.activity || updatedSearchFormData.activity.length === 0) {
          updatedSearchFormData.activity = [a];
        } else {
          updatedSearchFormData.activity = 
            Array.isArray(updatedSearchFormData.activity) && !updatedSearchFormData.activity.includes(a)
              ? [...updatedSearchFormData.activity, a] 
              : [updatedSearchFormData.activity, a]
        }
      } else if (!isChecked) {
        // remove category activities
        updatedSearchFormData.activity = updatedSearchFormData.activity.filter((act) => act !== a);
      }
    })
    // add active class for category selected
    // document.getElementById(c.id+'-category-label').parentNode.classList.toggle('checked');

    // remove selected activities to clear the way for selected group
    // [...document.querySelectorAll('.activity-wrapper.checked > label')].map((a) => { a.click(); });
    // select group of activites for the chosen category
    // c.listingTypes.map((a) => { document.getElementById(a+'-activity-label').click(); });

    // store state into local

    props.setNewSearchFormData(updatedSearchFormData);

    setHighlightSubmitButton(true);
  }


  const setActivityFilter = (a_id, isChecked) => {
    let updatedSearchFormData = (Object.keys(props.newSearchFormData).length > 0) ? { ...props.newSearchFormData } : {};

    if (isChecked) {
      // add activity if checked
      if (!updatedSearchFormData.activity || updatedSearchFormData.activity.length === 0) {
        updatedSearchFormData.activity = [a_id];
      } else {
        updatedSearchFormData.activity = 
          Array.isArray(updatedSearchFormData.activity) 
            ? [...updatedSearchFormData.activity, a_id] 
            : [updatedSearchFormData.activity, a_id]
      } 
    } else if (!isChecked) {
      // remove activity if unchecked
      updatedSearchFormData.activity = _.remove(updatedSearchFormData.activity, (a) => a !== a_id);
    }

    // update search form values
    props.setNewSearchFormData(updatedSearchFormData);

    setHighlightSubmitButton(true);
  }


  return (
    <>
      <Row className="justify-content-center my-1 mx-3 mx-md-0">
        <Col>
          <Form id="advancedSearchListings" onSubmit={(e) => props.newSearch(e.preventDefault())}>

            <Row className="d-flex justify-content-evenly justify-content-lg-between">
              <Col xs={12} lg={6} className="d-flex ps-0 mb-3">
                <Form.Group id="dateRangeStart" className="w-50">
                  {/*<Form.Label className="d-block text-center fs-5">Start Date</Form.Label>*/}
                  <Datetime
                    className="text-dark"
                    dateFormat="MM/DD/YYYY"
                    timeFormat={false}
                    onChange={changeDateRangeStart}
                    closeOnSelect={true}
                    isValidDate={(current) => {return current.isAfter( moment().add(-1, 'day') )}} // check date is NOT BEFORE TODAY

                    renderInput={(props, openCalendar, closeCalendar) => (
                      <InputGroup>
                        <InputGroup.Text><FontAwesomeIcon icon={faCalendarAlt} /></InputGroup.Text>
                        <Form.Control
                          type="text"
                          defaultValue={ ( dateRangeStart ) ? moment(dateRangeStart).format("MM/DD/YYYY") : "" }
                          placeholder="Start Date"
                          onFocus={openCalendar}
                          onChange={(e) => { changeDateRangeStart(e.target.value) }}
                          className="border-end-0"
                        />
                      </InputGroup>
                    )}
                  />
                </Form.Group>

                <Form.Group id="dateRangeEnd" className="w-50">
                  {/*<Form.Label className="d-block text-center fs-5">End Date</Form.Label>*/}
                  <Datetime
                    className="text-dark"
                    dateFormat="MM/DD/YYYY"
                    timeFormat={false}
                    onChange={changeDateRangeEnd}
                    closeOnSelect={true}
                    isValidDate={isValidEndDate}

                    renderInput={(props, openCalendar, closeCalendar) => (
                      <InputGroup>
                        <InputGroup.Text><FontAwesomeIcon icon={faCalendarAlt} /></InputGroup.Text>
                        <Form.Control
                          type="text"
                          defaultValue={ ( dateRangeEnd ) ? moment(dateRangeEnd).format("MM/DD/YYYY") : "" }
                          placeholder="End Date"
                          onFocus={openCalendar}
                          onChange={(e) => { changeDateRangeEnd(e.target.value) }}
                        />
                      </InputGroup>
                    )}
                  />
                </Form.Group>
              </Col>

              <Col xs={6} lg={2} className="px-0 mb-3">
                <Form.Group id="searchState">
                  {/*<Form.Label className="d-block text-center fs-5">State</Form.Label>*/}
                  <Form.Select id="state" name="state" value="MT" /*value={searchState}*/ onChange={setStateValue} onBlur={setStateValue}>
                    <option value="MT">Montana</option>
{/*
                    <option value={false}>State</option>
                    {
                      Object.keys(statesList).map(function (key, i) {
                        return (
                          <option value={statesList[key]} key={i}>{key}</option>
                        );
                      })
                    }
*/}
                  </Form.Select>
                </Form.Group>
              </Col>

              <Col xs={6} lg={4} className="pe-0 mb-3">
                <Form.Group id="searchText">
                  <InputGroup>
                    <InputGroup.Text>
                      <FontAwesomeIcon icon={faSearch} />
                    </InputGroup.Text>
                    <Form.Control name="searchText" defaultValue={props.newSearchFormData.searchText && props.newSearchFormData.searchText} type="text" placeholder="Search Term" onSubmit={setFormValues} onBlur={setFormValues} 
                      onChange={(e) =>
                        props.setNewSearchFormData({
                          ...props.newSearchFormData,
                          searchText: e.target.value !== "" ? e.target.value : null,
                        })
                      }
                    />
                  </InputGroup>
                </Form.Group>
              </Col>
            </Row>

            <hr />

            <Row>
              <Col className="mb-4 px-0">
                <Row>
                  <Col xs={2}>
                    <h5>Category:</h5>
                  </Col>
                  <Col xs={10} className="ps-3">
                    <h5 className="ps-3">Activity:</h5>
                  </Col>
                </Row>
                <Form.Group id="searchCategories">
                  {
                    Object.values(masterCategoryDefinitions).map(function (c, i) {
                      return (
                        <Row key={c.id} id={`${c.id}-category-container`} className="category-container border-bottom border-2 mb-3">
                          <Col xs={2}>
                              <Form.Check
                                type="checkbox"
                                name="category"
                                id={`${c.id}-category-wrapper`}
                                value={c.id}
                                className="me-3 mb-3"
                              >
                                <Form.Check.Input
                                  type="checkbox"
                                  isValid
                                  onChange={(e) => setCategoryFilter(c, e.target.checked)}
                                  checked={props.newSearchFormData.category ? props.newSearchFormData.category.includes(c.id) : false}
                                />
                                <Form.Check.Label 
                                  id={`${c.id}-category-label`}
                                  role="button"
                                  className="text-dark"
                                >
                                  {c.title}
                                </Form.Check.Label>
                              </Form.Check>
                            </Col>

                            <Col xs={10}>
                              { c.hasOwnProperty('listingTypes') && c.listingTypes.length > 0 &&
                                <div className="d-flex flex-wrap justify-content-start">
                                  {
                                    Object.values(c.listingTypes).map(function (a, i) {
                                      return (
                                        <Form.Check
                                          type="checkbox"
                                          name="activity"
                                          id={`${a}-activity-wrapper`}
                                          key={a}
                                          value={a}
                                          className="activity-wrapper mx-3 mb-3"
                                        >
                                          <Form.Check.Input 
                                            type="checkbox" 
                                            isValid 
                                            onChange={(e) => setActivityFilter(a, e.target.checked)}   
                                            checked={props.newSearchFormData.activity ? props.newSearchFormData.activity.includes(a) : false}                                      
                                          />
                                          <Form.Check.Label 
                                            id={`${a}-activity-label`}
                                            role="button"
                                            className="text-dark"
                                          >
                                            {listingTypesDefinitions[a].type.title}
                                          </Form.Check.Label>
                                        </Form.Check>
                                      );
                                    })
                                  }
                                </div>
                              }
                            </Col>
                        </Row>
                      );
                    })
                  }
                </Form.Group>
              </Col>
            </Row>
{/*
            <hr />

            <Row className="mt-3">
              {
                Object.values(listingTypesDefinitions).map(function (a, i) {
                  if ( a.hasOwnProperty('fields') && Object.keys(a.fields).length > 0 ) {
                    return (
                      <Row id={`listingTypeFilter-${a.id}`} key={a.id} className={`listingTypeFilter count-${Object.keys(a.fields).length} d-none`}>
                        <h6 className="h5"><small>{a.type.title} Filters:</small></h6>
                        <Form.Group id={`${a.id}-wrapper`} className="d-flex flex-row flex-wrap flex-fill justify-content-start">
                          {
                            Object.keys(a.fields).map(function (f, i) {
                              return (
                                <div key={f} className="listingTypeName px-0 me-3 mb-2">
                                  <h6>{a.fields[f].title}</h6>

                                  { a.fields[f].hasOwnProperty('specifications') &&
                                    <ul id={`listingTypeSpecification-${f}`} className="listingTypeSpecifications list-group ps-2">
                                      {
                                        Object.keys(a.fields[f].specifications).map(function (s, i) {
                                          return (
                                            <li key={s} className="listingTypeSpecification list-group-item py-0">
                                              <Form.Check
                                                type="checkbox"
                                                name={s}
                                                key={s}
                                                id={s}
                                                value={a.fields[f].specifications[s]}
                                                className="ps-0"
                                              >
                                                <Form.Check.Input type="checkbox" isValid />
                                                <Form.Check.Label
                                                  id={`${f}-${s}-label`}
                                                  role="button"
                                                  // onClick={() => setActivityFieldSpecificationFilter(f)}
                                                  className="text-body"
                                                >
                                                  {a.fields[f].specifications[s]}
                                                </Form.Check.Label>
                                              </Form.Check>
                                            </li>
                                          );
                                        })
                                      }
                                    </ul>
                                  }
                                </div>
                              );
                            })
                          }
                        </Form.Group>

                        <hr />
                      </Row>
                    );
                  }
                })
              }
            </Row>
*/}
            <Row className={`${props.stickyFooter == true ? 'row position-sticky bottom-0 bg-white pb-4' : ''}`}>
              <div className="d-flex justify-content-center mt-3">
                <Button variant={highlightSubmitButton?'primary':'outline-primary'} type="submit" className="px-5">Apply Search Filters</Button>
                &nbsp; &nbsp; &nbsp; &nbsp;
                { props.clearSearch && Object.keys(searchListingsFormData).length > 0 &&
                  <Button variant="outline-secondary" type="reset" className="px-5" onClick={() => props.clearSearch()}>Clear</Button>
                }
              </div>
            </Row>

          </Form>
        </Col>
      </Row>
    </>
  );
}
