import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Routes } from "../../routes";
import moment from "moment-timezone";
import queryString from "query-string";
import Cookies from "js-cookie";

import ListingService from "../../services/listing.service";
import BookingService from "../../services/booking.service";

import { listingTypesDefinitions, listingTypesIcons } from "../../listingTypes/listingTypes";

import { SearchListings } from "../../components/forms/searchPublicListings";
import { RequestBooking } from "../../components/forms/requestBooking";
import PublicFooter from '../../components/PublicFooter';
import { ImageCarousel } from '../../components/ImageCarousel';
import ListingMap from "../map/ListingMap";

import Preloader from "../../components/Preloader";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRunning, faEdit } from "@fortawesome/free-solid-svg-icons";

import { Col, Row, Card, Button, Container } from "react-bootstrap";


const ListingInfo = (props) => {
  let currentUserData = Cookies.get('authToken');
  if ( currentUserData && currentUserData !== 'null' ) {
    currentUserData = JSON.parse(currentUserData).data;
  } else {
    currentUserData = false;
  }

  const thisListingID = useParams();

  const [queryStatusListing, setQueryStatusListing] = useState(false);
  const [listingData, setListingData] = useState([]);
  const [galleryData, setGalleryData] = useState([]);
  useEffect(() => {
    if ( !queryStatusListing ) {
      ListingService.getListingPublic(thisListingID.id)
        .then(res => {
          setQueryStatusListing(res.status);
          if ( res.status === 200 ) {
            setListingData(res.body);
          }
        })
      }
  }, [thisListingID.id]);

  useEffect(() => {
    if ( queryStatusListing === 200 ) {
      document.title = listingData.title+" | Listing | REC-X";

      // Include Property images conditionally
      if ( Object.keys(listingData).length ) {
        let compiledGalleryData = listingData.gallery.length < 5 ? listingData.gallery.concat(listingData.propertyData.gallery) : listingData.gallery;
        setGalleryData(compiledGalleryData.filter(e => e));
      }
    }
  }, [queryStatusListing, listingData]);

  const[listingTypeDataOBJ, setListingTypeDataOBJ] = useState([]);
  useEffect(() => {
    if (listingData.listingTypeData) {
      setListingTypeDataOBJ(listingData.listingTypeData);
    }
  }, [thisListingID.id, listingData]);


  let addBookingFormData = {
    'listing': listingData._id,
    'property': listingData.property_id,
    'host': listingData.host_id,
    'guest_email': currentUserData ? currentUserData.email : null
  }
  const addBooking = (e) => {
    // e.preventDefault();

    if ( Object.keys(addBookingFormData).length > 6 ) {
      BookingService.addBooking(listingData._id, addBookingFormData)
        .then(
          res => {
            if (res.status === 200) {
              window.alert('Your booking for '+moment.utc(addBookingFormData.start).format('MM/DD/YYYY')+' has been submitted!');
              if ( currentUserData ) {
                // If user is logged in, load booking detail page
                props.history.push('/dashboard/booking/'+res.data._id);
              }
            } else {
              window.alert('An error occurred, please try again.');
            }
          },
          err => {
            // console.log(err);
          }
        );
    }

  }


  let searchListingsFormData = {};
  const searchListings = () => {
    let queryHash = queryString.stringify(searchListingsFormData);
    // Send to search page w/ hash parameters
    window.location.href = Routes.Search.path+'#'+queryHash;
  }

  const [listingIcon, setListingIcon] = useState(faRunning);
  useEffect(() => {
    if ( Object.keys(listingData).length > 0 && listingData.hasOwnProperty('listingType') && listingTypesIcons.hasOwnProperty(listingData.listingType[0]) ) {
      setListingIcon( listingTypesIcons[listingData.listingType[0]] );
    }
  }, [listingData]);



  // Check Listing ID Exists
  if ( queryStatusListing === 200 ) {

    return (
      <>
        <Row className="d-flex justify-content-center bg-gray-200 pt-4 pb-5">
          <Col xl={11}>
            <Card>
              <Card.Body className="d-md-flex justify-content-between align-items-center">

                <h1 className="h5">
                  <FontAwesomeIcon icon={listingIcon} className="me-2" />
                  <span className="text-primary">{listingData.title}</span>
                  { listingData.hasOwnProperty('propertyData') &&
                    <>
                      &nbsp;at <a href={`/property/${listingData.propertyData._id}`} className="text-underline">{listingData.propertyData.title}</a>
                    </>
                  }
                </h1>

                {currentUserData && // Check User viewing Profile is an admin or the User themself
                (currentUserData.id === listingData.host || currentUserData.roles.includes('ROLE_ADMIN')) &&
                  <Button variant="outline-tertiary" href={"/dashboard/listing/"+listingData._id}>
                    <FontAwesomeIcon icon={faEdit} className="me-2" /> Edit
                  </Button>
                }

              </Card.Body>
            </Card>
          </Col>
        </Row>


        <Row className="justify-content-center px-4 my-5">
          <Col>
            <Card className="bg-transparent border-0 overflow-hidden">
              <Card.Body className="p-0">

                <ImageCarousel galleryData={galleryData} />

              </Card.Body>
            </Card>
          </Col>
        </Row>


        <Row className="justify-content-center px-4 my-5">

          <Col xl={6}>
            <Card>
              <Card.Body>

                {listingData.description &&
                  <Row className="mb-3">
                    <Col>
                      <h6>Description:</h6>
                      {listingData.description}
                    </Col>
                  </Row>
                }

                {(listingData.check_in_time || listingData.check_out_time) &&
                  <Row className="mb-3">
                    {listingData.check_in_time &&
                      <Col md={6}>
                        <h6 className="d-inline">Check-in time:</h6> {moment(listingData.check_in_time).format('hh:mm A')}
                      </Col>
                    }
                    {listingData.check_out_time &&
                      <Col md={6}>
                        <h6 className="d-inline">Check-out time:</h6> {moment(listingData.check_out_time).format('hh:mm A')}
                      </Col>
                    }
                  </Row>
                }
{/*
                {(listingData.min_days || listingData.max_days) &&
                  <Row className="mb-3">
                    {listingData.min_days &&
                      <Col md={6}>
                        <h6 className="d-inline">Minimum days:</h6> {listingData.min_days}
                      </Col>
                    }
                    {listingData.max_days &&
                      <Col md={6}>
                        <h6 className="d-inline">Maximum days:</h6> {listingData.max_days}
                      </Col>
                    }
                  </Row>
                }
*/}
                {Object.keys(listingTypeDataOBJ).length > 0 &&
                  <Row className="mb-3">
                    <Col>
                      {
                        Object.keys(listingData.listingTypeData).map(function (fieldGroup, i) {
                          if ( listingData.listingType.includes(fieldGroup) ) {
                            return (
                              <div key={i} className="pt-3 mt-3 border-top border-4">
                                <h6 className="mb-0">{listingTypesDefinitions[fieldGroup].type.title}:</h6>
                                <ul className={`listingTypeData-${fieldGroup}`}>
                                  {
                                    Object.keys(listingTypeDataOBJ[fieldGroup]).map(function (fieldGroupData, i) {
                                      return (
                                        <li key={i}>
                                          <span className="fw-bold">
                                            {listingTypesDefinitions[fieldGroup].fields[fieldGroupData] &&
                                              listingTypesDefinitions[fieldGroup].fields[fieldGroupData].title
                                            }
                                            {fieldGroupData.indexOf('_specifications') > 0 &&
                                              listingTypesDefinitions[fieldGroup].fields[fieldGroupData.replace('_specifications','')].title+' Specifications'
                                            }
                                            &#x3a;
                                          </span>
                                          &nbsp;

                                          {listingTypeDataOBJ[fieldGroup][fieldGroupData] instanceof Array 
                                            ?
                                              <ul className={`listingTypeData-${fieldGroup}-specifications`}>
                                                {
                                                  listingTypeDataOBJ[fieldGroup][fieldGroupData].map(function (item, i) {
                                                    return (
                                                      <li key={i}>
                                                        {fieldGroupData.indexOf('_specifications') > 0 
                                                          ?
                                                            <span className="item">
                                                              {listingTypesDefinitions[fieldGroup].fields[fieldGroupData.replace('_specifications','')].specifications[item]}
                                                            </span>
                                                          :
                                                            <span className="item">
                                                              {listingTypesDefinitions[fieldGroup].fields[fieldGroupData].specifications[item]}
                                                            </span>
                                                        }
                                                      </li>
                                                    )
                                                  })
                                                }
                                              </ul>
                                            :
                                              <span className="item text-capitalize">
                                                {listingTypeDataOBJ[fieldGroup][fieldGroupData]}
                                              </span>
                                          }
                                        </li>
                                      )
                                    })
                                  }
                                </ul>
                              </div>
                            );
                          }
                        })
                      }
                    </Col>
                  </Row>
                }

                {listingData.rules &&
                  <Row className="mb-3">
                    <Col>
                      <h6>Property Rules:</h6>
                      {listingData.rules}
                    </Col>
                  </Row>
                }

                {listingData.rules_regulations_waivers &&
                  <Row className="mb-3">
                    <Col>
                      <h6>Rules, Regulations, & Waivers:</h6>
                      {listingData.rules_regulations_waivers}
                    </Col>
                  </Row>
                }

                {listingData.cancellation_policy &&
                  <Row className="mb-3">
                    <Col>
                      <h6>Cancellation Policy:</h6>
                      {listingData.cancellation_policy}
                    </Col>
                  </Row>
                }

              </Card.Body>
            </Card>
          </Col>

          <Col xl={6}>
            <Card>
              <Card.Body>

                <RequestBooking addBooking={() => addBooking()} listingData={listingData} formData={addBookingFormData} />

              </Card.Body>
            </Card>
          </Col>

        </Row>


        <Row className="justify-content-center px-4 my-5">
          <Col>
            <Card>
              <Card.Body className="w-100 p-0">
                {listingData.map_geoJSON &&
                  <ListingMap listingData={listingData} isMapSelected={true} />
                }

              </Card.Body>
            </Card>
          </Col>

        </Row>


        <PublicFooter />
      </>
    );

  } else if ( [204, 400, 404].includes(queryStatusListing) ) {
    return (
      <>
        <section className="section-header pt-5 pt-lg-6 pb-9 pb-lg-12 bg-primary overflow-hidden" id="home">
          <Container>
            <Row className="text-white">
              <Col xs={12} className="text-center mt-6">
                <div className="react-big-icon d-none d-lg-block pt-4"><span className="flaticon-fishing"></span></div>
              </Col>
            </Row>

            <figure className="position-absolute bottom-0 left-0 w-100 d-none d-md-block mb-n2">
              <svg className="fill-soft" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 3000 185.4">
                <path d="M3000,0v185.4H0V0c496.4,115.6,996.4,173.4,1500,173.4S2503.6,115.6,3000,0z" />
              </svg>
            </figure>

            <Row className="justify-content-center">
              <Col xl={4}>
                <Card>
                  <Card.Body className="text-center py-5">
                    <h4>Listing Not Found</h4>
                    <h5 className="mb-0 text-danger">{thisListingID.id}</h5>
                  </Card.Body>
                </Card>
              </Col>
            </Row>

            <div className="text-white">
              <SearchListings listingTypesDefinitions={listingTypesDefinitions} formData={searchListingsFormData} searchListings={searchListings} />
            </div>

          </Container>
        </section>

        <PublicFooter />
      </>
    );
  } else {
    return (
      <div className="mt-5" style={{height: '40vh'}}>
        <Preloader message="Loading..." logoSize={50} show={true} />
      </div>
    );
  }

};
export default ListingInfo;
