import React, { useState } from "react";
import moment from "moment-timezone";
import Datetime from "react-datetime";
import Cookies from "js-cookie";

import Preloader from "../../components/Preloader";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt, faEnvelope } from "@fortawesome/free-solid-svg-icons";

import { Col, Row, Form, Button, InputGroup } from "react-bootstrap";


export const RequestBooking = (props) => {
  let currentUserData = Cookies.get('authToken');
  if ( currentUserData && currentUserData !== 'null' ) {
    currentUserData = JSON.parse(currentUserData).data;
  } else {
    currentUserData = false;
  }

  const [final_price, setFinalPrice] = useState(props.listingData.base_rate * props.listingData.min_days);
  props.formData.final_price = final_price;

  const [occupants, setOccupants] = useState(1);
  props.formData.occupants = occupants;

  function setFormValues(e) {
    props.formData[e.target.name] = e.target.value;
    if ( 'occupants' === e.target.name ) { setOccupants(e.target.value); }
    if ( beginDate && endDate ) { calcFinalPrice(); }
  };

  function calcFinalPrice() {
    if ( beginDate && endDate ) {
      let duration = moment.duration(moment(endDate).diff(beginDate)).asDays();
      setFinalPrice( Math.ceil(duration) * occupants * props.listingData.base_rate );
    }
  };

  const [beginDate, setBeginDate] = useState("");
  const changeBeginDate = (e) => {
    let startDateTime = moment( e.startOf('day').format('MM/DD/YYYY') + ' ' + (props.listingData.check_in_time?moment(props.listingData.check_in_time).format('HH:mm'):'00:01'), 'MM/DD/YYYY HH:mm');
    setBeginDate(startDateTime.format());
    props.formData.start = startDateTime.format();

    // auto-set endDate to match min_days for listing
    changeEndDate(moment(e).add((props.listingData.min_days - 1), 'day'));

    // auto-click End Date field
    document.querySelector('#end input').focus();
  };

  const [endDate, setEndDate] = useState("");
  const changeEndDate = (e) => {
    let endDateTime = e?moment( e.startOf('day').format('MM/DD/YYYY') + ' ' + (props.listingData.check_out_time?moment(props.listingData.check_out_time).format('HH:mm'):'23:59'), 'MM/DD/YYYY HH:mm'):null; // handle clearing endDate when beginDate is selected
    setEndDate(endDateTime.format());
    props.formData.end = endDateTime.format();
    calcFinalPrice();
  };


  var isValidStartDate = function(current) {
    // check date MATCHES API ARRAY availableBookingDates
    if ( !props.listingData.availableBookingDates.includes(moment(current).format('YYYY-MM-DD')) ) { return false; }

    // check DAY OF WEEK IS set to AVAILABLE (listing config)
    if ( props.listingData.weekdaysAvailable && Object.keys(props.listingData.weekdaysAvailable).length > 0 && !props.listingData.weekdaysAvailable.includes(current.format('dddd').toLowerCase()) ) { return false; }

    // check date is AFTER START OF AVAILABILITY
    if ( props.listingData.startAvailable ) {
      let startAvailable = moment(props.listingData.startAvailable);
      if ( current < startAvailable ) { return false; }
    }

    // check date is BEFORE END OF AVAILABILITY
    if ( props.listingData.endAvailable ) {
      let endAvailable = moment(props.listingData.endAvailable);
      if ( current > endAvailable ) { return false; }
    }

    // check date is NOT TODAY OR EARLIER
    var tomorrow = moment().add( 1, 'day' );
    if ( !current.isAfter( tomorrow ) ) { return false; }

    return true;
  };

  var isValidEndDate = function(current) {
    // check date MATCHES API ARRAY availableBookingDates
    if ( !props.listingData.availableBookingDates.includes(moment(current).format('YYYY-MM-DD')) ) { return false; }

    // check date is NOT BEFORE min_days after user-selected start
    if ( beginDate && props.listingData.min_days > 1 ) {
      let min_start_date = moment(beginDate).add( props.listingData.min_days-2, 'day' );
      if ( !current.isAfter(min_start_date) ) { return false; }
    }

    // check date is NOT AFTER max_days after user-selected start
    if ( beginDate && props.listingData.max_days ) {
      let max_end_date = moment(beginDate).add( (props.listingData.max_days-1), 'day' ).toDate();
      if ( !current.isBefore(max_end_date) ) { return false; }
    }

    // check DAY OF WEEK IS set to AVAILABLE (listing config)
    if ( props.listingData.weekdaysAvailable && Object.keys(props.listingData.weekdaysAvailable).length > 0 && !props.listingData.weekdaysAvailable.includes(current.format('dddd').toLowerCase()) ) { return false; }

    // check date is AFTER START OF AVAILABILITY
    if ( beginDate || props.listingData.startAvailable ) {
      let startAvailable = beginDate?moment(beginDate).add(-1, 'day'):moment(props.listingData.startAvailable);
      if ( current < startAvailable ) { return false; }
    }

    // check date is BEFORE END OF AVAILABILITY
    if ( props.listingData.endAvailable ) {
      let endAvailable = moment(props.listingData.endAvailable);
      if ( current > endAvailable ) { return false; }
    }

    // check date is NOT TODAY OR EARLIER
    var tomorrow = moment().add( 1, 'day' );
    if ( !current.isAfter(tomorrow) ) { return false; }

    return true;
  };

  if ( Object.keys(props.listingData).length > 0 ) {
    return (
      <>
        <Row>
          <Col>
            <Form id="requestBooking" onSubmit={ (e) => props.addBooking(e.preventDefault()) }>

              <Row>
                <Col className="mb-3">
                  <Form.Group id="guest_email">
                    <Form.Label>Your Email Address</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faEnvelope} />
                      </InputGroup.Text>
                      {currentUserData
                        ?
                        <Form.Control disabled value={currentUserData.email} required type="email" name="guest_email" placeholder="guest@email.com" />
                        :
                        <Form.Control required type="email" name="guest_email" placeholder="your@email.com" onChange={setFormValues} onBlur={setFormValues} />
                      }
                    </InputGroup>
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col md={4} className="mb-3">
                  <Form.Group id="start">
                    <Form.Label>Start Date</Form.Label>
                    <Datetime
                      dateFormat="MM/DD/YYYY"
                      timeFormat={false}
                      onChange={changeBeginDate}
                      closeOnSelect={true}
                      isValidDate={isValidStartDate}

                      renderInput={(props, openCalendar, closeCalendar) => (
                        <InputGroup>
                          <InputGroup.Text><FontAwesomeIcon icon={faCalendarAlt} /></InputGroup.Text>
                          <Form.Control
                            required
                            type="text"
                            defaultValue={ beginDate ? moment(beginDate).format("MM/DD/YYYY") : "" }
                            placeholder="MM/DD/YYYY"
                            onFocus={openCalendar}
                            onChange={(e) => { setBeginDate(e.target.value) }}
                          />
                        </InputGroup>
                      )}
                    />
                  </Form.Group>
                </Col>

                <Col md={4} className="mb-3">
                  <Form.Group id="end">
                    <Form.Label>End Date</Form.Label>
                    <Datetime
                      dateFormat="MM/DD/YYYY"
                      timeFormat={false}
                      onChange={changeEndDate}
                      closeOnSelect={true}
                      isValidDate={isValidEndDate}

                      renderInput={(props, openCalendar, closeCalendar) => (
                        <InputGroup>
                          <InputGroup.Text><FontAwesomeIcon icon={faCalendarAlt} /></InputGroup.Text>
                          <Form.Control
                            required
                            type="text"
                            defaultValue={ ( endDate ) ? moment(endDate).format("MM/DD/YYYY") : "" }
                            placeholder="MM/DD/YYYY"
                            onFocus={openCalendar}
                            onChange={(e) => { setEndDate(e.target.value) }}
                            onBlur={calcFinalPrice()}
                          />
                        </InputGroup>
                      )}
                    />
                  </Form.Group>
                </Col>

                <Col md={4} className="mb-3">
                  <Form.Group id="occupants">
                    <Form.Label>Your Party Size</Form.Label>
                    <Form.Control required type="number" name="occupants" value={occupants} min={1} max={props.listingData.max_guests} onChange={setFormValues} onBlur={setFormValues} />
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col className="text-center mt-3">
                  <h6 className="text-uppercase mb-0">
                    Total: { '\u0024'}{final_price ? final_price.toFixed(2) : props.listingData.base_rate.toFixed(2) }
                  </h6>
                </Col>
              </Row>

              <div className="mt-3 d-flex justify-content-evenly">
                <Button variant="primary" type="submit" className="px-4">Request Booking</Button>
              </div>

            </Form>
          </Col>
        </Row>
      </>
    );
  } else {
    return (
      <div className="mt-5" style={{height: '40vh'}}>
        <Preloader message="Loading..." logoSize={50} show={true} />
      </div>
    );
  }
};
