import React from 'react';
import { Link } from 'react-router-dom';

import logoText from "../assets/img/rec-x/logo/logo-text-black-rec-x.svg";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faInstagram } from "@fortawesome/free-brands-svg-icons";

import { Col, Row, Navbar, Image } from 'react-bootstrap';

const PublicFooter = () => {

  return (
    <footer className="footer py-5 px-5 bg-gray-100">

        <Row>
          <Col md={8} className="d-flex flex-column flex-md-row align-items-center justify-content-start">
            <a href="/">
              <Image src={logoText} width="200" className="logo mb-4 mb-md-0" alt="REC-X logo" />
            </a>
            <p className="small text-center text-md-left mt-3 mt-md-0 mb-0 ms-md-4">REC-X is a marketplace for recreational experiences on private properties. Use our tools to find your next activity and connect with property owners offering unique experiences catered to your goals!</p>
          </Col>

          <Col md={3} lg={2} className="ms-md-auto">
            <p className="small text-center text-md-left mt-3 mt-md-0 mb-0">
              <a href="#" className="text-black">For Owners</a>
              <a href="#" className="text-black ms-4">FAQ</a>
              <br className="d-none d-md-block" />
              <a href="#" className="text-black ms-4 ms-md-0">About</a>
              &nbsp; &nbsp;
            </p>
          </Col>
        </Row>

        <Row className="border-top border-2 border-gray-500 mt-5 pt-5">
          <Col md={6} lg={4} xl={3}>
            <p className="small text-center text-md-left m-0">Copyright REC-X &copy; <span className="current-year">{new Date().getFullYear()}</span>. All rights reserved.</p>
          </Col>

          <Col>
            <p className="small text-center text-md-left mt-3 mt-md-0 mb-0">
              <a href="#" className="text-black">Privacy</a>
              &nbsp; &nbsp;
              <a href="#" className="text-black">Terms</a>
              &nbsp; &nbsp;
              <a href="#" className="text-black"><FontAwesomeIcon icon={faFacebook} size="lg" /></a>
              &nbsp; &nbsp;
              <a href="#" className="text-black"><FontAwesomeIcon icon={faInstagram} size="lg" /></a>
            </p>
          </Col>
        </Row>

    </footer>
  );
}
export default PublicFooter;
